import Wrapper from './Wrapper'

const LifeInsuIcon = ({ className }: { className?: string }) => {
  return (
    <Wrapper>
      <path d="M960 768a529.858 529.858 0 0 1-48-16c-63.69-24.68-85.72-47.52-128-64-34.74-13.54-61.65-13.46-112-16-116.71-5.88-139.62 33.19-234.39 19.31C376.8 682.4 343.98 660.13 336 672c-9.03 13.44 22.38 57.77 64 80 57.14 30.52 138.92 22.27 160-16 11.72-21.27-5.3-33.69 0-64 10.59-60.57 87.72-63.6 144-128 45.57-52.14 78.44-145.6 40.45-213.68-23.58-42.26-79.8-84.36-144.39-72.2-51.65 9.73-94.18 52.06-108.3 108.3-16.74-63.08-69.25-106.62-126.35-108.3-61.42-1.81-111.8 43.89-126.35 90.25C213.45 429.97 297.2 519.6 320 544c68.76 73.59 148.08 95.6 144 144-1.56 18.51-14.46 30.59-16 32-37.93 34.51-124.86-6.89-144-16-60.66-28.89-65.44-53.97-137.13-84.89-28.12-12.13-67.84-25.71-72.2-18.05-3.49 6.13 17.95 21.88 49.33 54.94 32.66 34.4 47.95 57.91 58.97 71.41 50.95 62.46 167.17 106.7 270.74 108.3 44.68.69 124.63-5.98 198.29-19.7 25.49-4.75 57.81-11.75 96 0 42.29 13.01 75.91 44.15 80 48 23.65 22.28 26.09 34.81 48 48 22.84 13.75 46.88 16.19 64 16" />
    </Wrapper>
  )
}

export default LifeInsuIcon
