import { TabsListTypes } from '../_types'

const TabsList = ({
  subCategory,
  setSubCategory,
  faqs,
  extraClass,
  setOpenDrawer = () => {},
}: TabsListTypes) => {
  // the onClick handler function to set the sub category when a tab is clicked --in order to filter from the faqs,
  // and then to close the drawer when one tab is selected
  const handleTabsClick = (name: string) => {
    setSubCategory(name)
    setOpenDrawer(false)
  }
  return (
    <div className={`${extraClass}`}>
      <ul className="capitalize">
        {faqs?.map(({ category, sub_categories }) => (
          <li key={category}>
            <h3 className="capitalize font-secondary font-medium text-lg sm:text-2xl text-left text-black md:mb-[14px] mt-5 border-0 pointer-events-none">
              {category}
            </h3>
            <ul>
              {sub_categories.map(({ name }) => (
                <li key={name}>
                  <button
                    className={`capitalize text-base font-primary sm:leading-[32px] my-2 ${
                      name === subCategory
                        ? 'text-black px-2.5 border-0 transition-all ease-in-out delay-300 border-l-[3px] border-solid border-black'
                        : 'text-subHead'
                    }`}
                    onClick={() => handleTabsClick(name)}>
                    {name}
                  </button>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TabsList
