import ReactHtmlParser from 'react-html-parser'

const LeadsCard = ({ body, image }: { body: string; image: string }) => {
  return (
    <div className="bg-white overflow-hidden  flex shrink-0 border-2 border-solid border-black rounded-3xl max-w-[486px] max-h-[292px]">
      <img
        src={image}
        alt="Historical Place"
        className="w-2/5 sm:w-1/2 hidden 500:block"
      />
      <div className="flex flex-col text-left p-2.5 sm:pt-[25px] sm:px-5">
        <span className="font-medium text-syne text-xs 500:text-sm">
          {ReactHtmlParser(body)}
        </span>
      </div>
    </div>
  )
}

export default LeadsCard
