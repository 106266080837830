import React from 'react'

import { Table } from 'antd'
import {
  AGMHistoryColumns,
  getAGMData,
} from 'constants/pages/ticker-profile'
import { useParams } from 'react-router-dom'
import { getAGMHistory } from 'services/CompanyProfileService'

const AGMHistory = () => {
  const [agmHistory, setAgmHistory] = React.useState([])
  const data = getAGMData(agmHistory)
  const tickerSymbol = useParams().ticker
  
  const fetchData = async () => {
    try {
      const resp = await getAGMHistory(tickerSymbol)
      setAgmHistory(resp)
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])
  return (
    <div className='overflow-scroll'>
      <Table columns={AGMHistoryColumns} dataSource={data} />
    </div>
  )
}

export default AGMHistory