import { CompanyDetailType } from 'components/index-block/_type'
import { axiosInstanceNepse } from './axios'

export const getTickerList = async (): Promise<any[]> => {
  const res = await axiosInstanceNepse.get(`/ticker-list/`)
  return res.data.response
}

export const getTickerDetail = async (
  ticker: string,
): Promise<CompanyDetailType> => {
  const res = await axiosInstanceNepse.get(`/ticker-live-data/${ticker}`)
  return res.data.response
}

export const getTickerTimeline = async (ticker: string): Promise<any[]> => {
  const res = await axiosInstanceNepse.get(`/ticker-live-chart/${ticker}`)
  return res.data.response
}
