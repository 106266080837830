import { axiosInstanceNepse } from './axios'

export const getIndicesList = async (): Promise<any[]> => {
  const res = await axiosInstanceNepse.get(`/indices-data/`)
  return res.data.response
}

export const getIndicesDetail = async (index: string): Promise<any> => {
  const res = await axiosInstanceNepse.get(`/indices-data/${index}`)
  return res.data.response[0] || {}
}

export const getIndicesTimeline = async (index: string): Promise<any[]> => {
  const res = await axiosInstanceNepse.get(`/indices-chart/${index}`)
  return res.data.response
}

export const getTopGainers = async (index: string): Promise<any[]> => {
  const res = await axiosInstanceNepse.get(`/top-gainers/${index}`)
  return res.data.response
}
export const getTopLosers = async (index: string): Promise<any[]> => {
  const res = await axiosInstanceNepse.get(`/top-losers/${index}`)
  return res.data.response
}
