import styled, { css } from 'styled-components'

type SubHeadingProps = {
  about?: any // TODO: Need to update this type for about; boolean was showing issue, so need to look into it.
}

export const TooltipWrapper = styled.div`
  width: 582px;
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  z-index: 2;
  pointer-events: auto;
  @media (max-width: 767px) {
    width: 390px;
    margin-top: 60px;
  }
`

export const NepseContainer = styled.div<{
  absolutePositioning: boolean
}>`
  background-color: #FFFFFF;
  background: #ffffff;
  border: 4px solid #000000;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  ${(props) => props.absolutePositioning && `position: absolute;`}
  width: 582px;
  bottom: -63px;
  z-index: 2;
  @media (max-width: 767px) {
    width: 390px;
    padding: 10px;
  }
`

export const NepseHeading = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  text-align: left;
  padding-left: 8px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const SubHeading = styled.h3<SubHeadingProps>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #797979;
  display: block;
  text-align: left;
  ${(props) =>
    !props.about &&
    css`
      @media (max-width: 767px) {
        font-size: 8px;
      }
    `}
`

export const SubHeadingValue = styled.span<SubHeadingProps>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;

  ${(props) =>
    !props.about &&
    css`
      @media (max-width: 767px) {
        font-size: 10px;
      }
    `}
`

export const RecapContainer = styled.div<{
  fullBorder: boolean | undefined
  firstChild: boolean | undefined
  lastChild?: boolean | undefined
  about?: boolean | undefined
}>`
  padding: 0 9px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 20%;
    right: 0;
    width: 2px;
    height: 50%;
    background: #d9d9d9;
    ${(props) => props.fullBorder && `top: 0; height: 100%;`}
    ${(props) => props.firstChild && `padding-left: 0px;`}
    ${(props) => props.lastChild && `width: 0px;`}
    ${(props) =>
      props.about &&
      props.fullBorder &&
      `
    @media (max-width: 450px) {
      display: none;
    }    
    `}
  }
  &:last-child {
    &::after {
      width: 0px;
    }
  }
`

export const CardContainer = styled.div<{ reverseFlex: boolean }>`
  background: #ffffff;
  border-left: 4px solid #000000;
  border-right: 4px solid #000000;
  border-top: 4px solid #000000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: ${(props) => (props.reverseFlex ? `row-reverse` : 'row')};
  min-height: 198px;
  &::before {
    content: '';
    position: absolute;
    bottom: -17px;
    left: -4px;
    width: 4px;
    height: 20px;
    background: #000000;
    margin-right: 10px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -17px;
    right: -4px;
    width: 4px;
    height: 20px;
    background: #000000;
  }
`

export const StoryHeading = styled.h3`
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
`

export const StoryDescription = styled.div`
  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
`

export const ScrollCardContainer = styled.div`
  max-height: 290px;
  overflow: scroll;
`
