import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'

export type IconProps = {
  src?: string
  size: number
  onClick?: () => void
}

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
`

export const CrossIcon = ({ size, onClick }: IconProps) => (
  <IconWrapper onClick={onClick}>
    <CloseOutlined style={{ fontSize: size }} />
  </IconWrapper>
)

export default CrossIcon
