import axiosInstance from './axios'

export const getOurServices = async (): Promise<any[]> => {
  const res = await axiosInstance.get('/dashboard/api/v1/our-services/list')
  const resData = await res.data
  return resData
}

export const getWhyAbc = async (): Promise<any[]> => {
    const res = await axiosInstance.get('/dashboard/api/v1/why-abc/list')
    const resData = await res.data
    return resData
  }
