import React, {
  Dispatch,
  SetStateAction,
} from 'react'

import Lottie from 'react-lottie'

import animationData from './abcLoader.json'

interface LoaderProps {
  isLoopComplete?: Dispatch<SetStateAction<boolean>>
}

export const Loader = ({ isLoopComplete }: LoaderProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div className="h-screen w-screen flex items-center overflow-hidden">
      <Lottie
        options={defaultOptions}
        style={{ height: 'auto' }}
        eventListeners={[
          {
            eventName: 'loopComplete',
            callback: () => (isLoopComplete ? isLoopComplete(false) : ''),
          },
        ]}
      />
    </div>
  )
}
