import Wrapper from './Wrapper'

const MicroFinanceIcon = ({ className }: { className?: string }) => {
  return (
    <Wrapper>
      <path d="M64 928h320c-18.61-21.62-46.02-58.97-64-112-20.74-61.17-19.12-114.27-16-144h176c51.61-106.32 47.52-145.19 32-160-13.62-13-33.18-4.73-64-16-40.62-14.85-65.23-50.62-80-80 21.2 5.29 49.5 14.66 80 32a317.235 317.235 0 0 1 64 48c-.34-37.14 19.11-69.03 48-80 20.55-7.8 39.84-2.72 48 0a318.857 318.857 0 0 0-48 48 318.363 318.363 0 0 0-32 48c-35.71-61.81-45.14-113.82-48-144-2.38-25.16-5.72-64.91 16-80 12.31-8.55 27.53-5.52 32-16 4.28-10.03-4.16-25.71-16-32-19.55-10.39-47.68 5.2-48 16-.13 4.47 4.55 6 16 16 21.93 19.15 20.5 27.25 32 32 16.46 6.8 43.73.22 48-16 2.73-10.37-4.39-23-16-32 21.27-11.95 34.54-25.64 32-32-2.6-6.5-22.54-7.26-48 0 7.97-13.58 6.17-27.79 0-32-6.72-4.59-22.88-.22-32 16-13.02-17.64-33.91-23.39-48-16-9.89 5.19-20.09 18.77-16 32 3.28 10.62 13.97 15.18 16 16-27.68 17.37-33.67 26.69-32 32 3.02 9.61 29.92 2.09 48 16 20.7 15.93 15.98 49.29 16 80 .06 97.67 48 112.1 48 208 0 21.71-2.45 54.95-16 96h160c3.12 29.73 4.74 82.83-16 144-17.98 53.03-45.39 90.38-64 112h368" />
    </Wrapper>
  )
}

export default MicroFinanceIcon
