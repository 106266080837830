import React, {
  useEffect,
  useState,
} from 'react'

import DynamicPage from 'components/dynamicPage'
import Footer from 'components/footer'
import Header from 'components/header'
import { Loader } from 'components/Loader/Loader'
import AboutUs from 'pages/AboutUs'
import BlogCategory from 'pages/BlogCategory'
import BlogDetail from 'pages/BlogDetail'
import Blogs from 'pages/Blogs'
import Careers from 'pages/Careers'
import Contact from 'pages/Contact'
import FAQs from 'pages/FAQs'
import OfflineDownloads from 'pages/OfflineDownloads'
import Services from 'pages/Services'
import StepOne from 'pages/StepOne'
import TickerProfile from 'pages/TickerProfile'
import {
  Route,
  Routes,
} from 'react-router-dom'
import { getPageList } from 'services/PageService'

export const ABCRoutes = () => {
  const [pageList, setPageList] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    try {
      setLoading(true)

      const res: any = await getPageList()

      if (res) {
        setPageList(res.results)
        // setTimeout(() => {
        //   setLoading(false)
        // }, 2800)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {loading ? (
        <>
          <Loader isLoopComplete={setLoading} />
        </>
      ) : (
        <>
          <Header />

          <Routes>
            <Route path="/" element={<StepOne />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog-detail/:id" element={<BlogDetail />} />
            <Route path="/blog-category/:category" element={<BlogCategory />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/ticker-profile/:ticker" element={<TickerProfile />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/offline-downloads" element={<OfflineDownloads />} />

            {/* for pages like privacy policy, terms & conditions etc */}
            {pageList?.map?.((page, ind) => (
              <Route
                key={ind}
                path={`/${page?.slug}`}
                element={<DynamicPage pageSlug={page?.slug} />}
              />
            ))}
          </Routes>

          <Footer />
        </>
      )}
    </>
  )
}

export default ABCRoutes
