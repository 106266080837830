import { BlogCardProps } from 'components/blog-card/_types'
import axiosInstance from './axios'

export type BlogListResult = {
  count: number
  next: string
  previous: string
  results: BlogCardProps[]
}

export const getBlogList = async (
  pageNumber: number = 1,
): Promise<BlogListResult> => {
  const res = await axiosInstance.get(
    `/dashboard/blog/api/list?page=${pageNumber}`,
  )
  const resData = await res.data
  return resData
}

export const getBlogDetail = async (
  id: string | undefined,
): Promise<BlogListResult> => {
  const res = await axiosInstance.get(`/dashboard/blog/api/${id}/detail`)
  const resData = await res.data
  return resData
}

export const getBlogCategoryList = async (
  category: string | undefined = 'Trading',
  pageNumber: number = 1,
): Promise<BlogListResult> => {
  const res = await axiosInstance.get(
    `/dashboard/blog/api/list?page=${pageNumber}&category=${category}`,
  )
  const resData = await res.data
  return resData
}
