import { Modal as AntdModal } from 'antd'
import { LineChartWithTooltip } from 'components/charts'
import Header from 'components/header'
import IndexBlock from 'components/index-block'
import { CompanyIndexType } from 'components/index-block/_type'
import { useWindowDimensions } from 'hooks'
import moment from 'moment'
import styled from 'styled-components'
import {
  BankingIcon,
  DevBankIcon,
  FinanceIcon,
  HotelsIcon,
  HydroIcon,
  InvestmentIcon,
  LifeInsuIcon,
  ManufactureIcon,
  MicroFinanceIcon,
  NepseIcon,
  NonLifeInsuIcon,
  OthersIcon,
  TradingIcon,
} from 'ui/Icons/indices-icons'
import MutualFundIcon from 'ui/Icons/indices-icons/MutualFundIcon'

import { ModalProps } from './_types'

const indexIcons: any = {
  nepse: <NepseIcon />,
  default: <NepseIcon />,
  banking: <BankingIcon />,
  'development-bank': <DevBankIcon />,
  finance: <FinanceIcon />,
  float: '',
  'hotels-and-tourism': <HotelsIcon />,
  hydropower: <HydroIcon />,
  investment: <InvestmentIcon />,
  'life-insurance': <LifeInsuIcon />,
  'manu.&-pro.': <ManufactureIcon />,
  microfinance: <MicroFinanceIcon />,
  'mutual-fund': <MutualFundIcon />,
  'non-life-insurance': <NonLifeInsuIcon />,
  others: <OthersIcon />,
  'sen.-floast': '',
  trading: <TradingIcon />,
  sensitive: '',
}

const IndexModal = ({
  showModal,
  setShowModal,
  title,
  index,
  index_name,
  percentage,
  change,
  negative,
  graphData,
  gainers,
  losers,

  tickerDetail,
}: ModalProps) => {
  const { width } = useWindowDimensions()
  const strokeWidth = () => {
    let lineStroke = 4
    if (width < 630) lineStroke = 3
    if (width < 430) lineStroke = 2
    return lineStroke
  }
  const minValue = graphData
    ? Math.min(...graphData?.map((item) => item?.stock_index))
    : undefined
  const maxValue = graphData
    ? Math.max(...graphData?.map((item) => item?.stock_index))
    : undefined

  const median = minValue && maxValue ? (minValue + maxValue) / 2 : 0

  // const chartBase =
  //   maxValue && minValue ? minValue - (maxValue - minValue) * 0.1 : 0

  const chartBase = median

  const chartOrigin = minValue || 0
 
  return (
    <AntdModal
      // style={{ top: 0, bottom: 0 }}
      title=""
      centered
      open={showModal}
      closable={false}
      width={'100%'}
      footer={null}
      mask={false}
      wrapClassName={tickerDetail?.percentage_change===0 ? `blue` : `${negative ? 'red' : 'green'}` }
      className={` text-white`}
      onCancel={() => setShowModal(false)}>
      <>
        <Header modalHeader />
        <IndicesContainer className={tickerDetail?.percentage_change===0 ? `blue` : `${negative ? 'red' : 'green'}`}>
          <div className="flex  md:hidden justify-between align-top  px-5  pt-[10px] ">
            <div className="w-[100px]  ">
              {/* for mobile view */}
              {indexIcons[tickerDetail?.slug]
                ? indexIcons[tickerDetail?.slug]
                : indexIcons['default']}
            </div>
            <button
              className="self-start pt-3 px-3"
              onClick={() => setShowModal(false)}>
              <img src="/images/close_icon.svg" className="w-7.5 md:w-11 " />
            </button>
          </div>

          <div className="flex justify-between items-start px-5 md:px-[65px] pb-0 pt-[10px] md:pt-16">
            <div>
              <p className="text-7.5 md:text-[48px] md:leading-[58.51px] font-bold font-primary">
                {title}
              </p>
              <p className="text-base md:leading-[58.51px] font-primary">
                Updated:{' '}
                {tickerDetail?.calculated_on
                  ? moment(tickerDetail?.calculated_on).fromNow()
                  : 'N/A'}
              </p>
            </div>
            <button
              className="hidden md:block"
              onClick={() => setShowModal(false)}>
              <img src="/images/close_icon.svg" className="w-7.5 md:w-11" />
            </button>
          </div>
          <div className="relative flex flex-col-reverse xl:flex-row justify-between mt-5.5">
            <div className="flex w-full  items-center px-8 md:px-16 justify-between">
              <div className="hidden md:block md:min-w-[352px] ">
                {indexIcons[tickerDetail?.slug]
                  ? indexIcons[tickerDetail?.slug]
                  : indexIcons['default']}
              </div>
              <div className="flex-1">
                <LineChartWithTooltip
                  extraClass="max-w-full"
                  data={
                    graphData
                    // ? [{ time: '', stock_index: chartOrigin }, ...graphData]
                    // : []
                  }
                  width="100%"
                  height={200}
                  strokeWidth={strokeWidth()}
                  // origin={graphData?.[0]?.stock_index}
                  origin={+tickerDetail?.latest_price - tickerDetail?.latest_price * tickerDetail?.percentage_change / 100}
                  nepse
                />
              </div>
            </div>
            <div className="px-5 xl:ml-0 xl:absolute right-[65px] flex flex-col tablet:flex-row tablet:justify-between tablet:items-center 700:px-[65px]">
              <span className="text-7.5 md:text-[48px] font-bold font-monsterrat">
                {index}
              </span>
              <span className="text-xl leading-[24.38px] font-bold font-monsterrat">
                {`${change ? change + ' %' : '-'} `}
              </span>
            </div>
          </div>
          <div className="my-6 px-5 700:px-[65px]">
            <span className="flex justify-center 700:justify-start text-xl font-700 font-primary mb-5">
              Top Gainers
            </span>
            <div className="grid gap-x-5 gap-y-5 767:grid-cols-2 sm:grid-cols-2 xl:grid-cols-4 mt-6">
              {gainers.map((gainer: CompanyIndexType, index) => (
                <IndexBlock
                  key={`${gainer.ticker}${index}`}
                  // name={gainer.symbol}
                  // lastTradedPrice={gainer.ltp}
                  // logo={gainer.logo}
                  // change={gainer.percent_change}
                  // negative={gainer.percent_change < 0}
                  companyInfo={gainer}
                />
              ))}
            </div>
          </div>
          <div className="my-6 px-5 700:px-[65px] tablet:pb-5">
            <span className="flex justify-center 700:justify-start text-xl font-700 font-primary text-center">
              Top Losers
            </span>
            <div className="grid gap-x-5 gap-y-5 767:grid-cols-2 sm:grid-cols-2 xl:grid-cols-4 mt-6">
              {losers.map((loser: CompanyIndexType, index) => (
                <IndexBlock
                  key={`${loser.ticker}${index}`}
                  // name={loser.symbol}
                  // lastTradedPrice={loser.ltp}
                  // logo={loser.logo}
                  // change={loser.percent_change}
                  // negative={loser.percent_change < 0}
                  companyInfo={loser}
                />
              ))}
            </div>
          </div>
        </IndicesContainer>
      </>
    </AntdModal>
  )
}

const IndicesContainer = styled.div`
  display: grid;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

export default IndexModal
