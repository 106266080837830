import { ReactNode } from 'react'

const Wrapper = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Grid"
      viewBox="0 0 1024 1024"
      stroke="#ffffff"
      strokeWidth={8}
      strokeMiterlimit={10}
      fill="none"
      className={`${className}`}>
      {children}
    </svg>
  )
}

export default Wrapper
