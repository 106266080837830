import Wrapper from './Wrapper'

const DevBankIcon = () => {
  return (
    <Wrapper>
      <g>
        <path d="M960 944H104.19c-12.21 0-22.1-9.89-22.1-22.1v-22.1c0-12.21 9.89-22.1 22.1-22.1H833.5c12.21 0 22.1-9.89 22.1-22.1l-1.04-423.57c0-12.21-9.89-22.1-22.1-22.1h-47.39c-10.45 0-18.92 8.47-18.92 18.92v386.23c0 10.45-8.47 18.92-18.92 18.92H169.44c-12.21 0-22.1-9.89-22.1-22.1V432.04c0-12.21-9.89-22.1-22.1-22.1h-22.1c-12.21 0-22.1-9.89-22.1-22.1v-10.6c0-12.21 9.89-22.1 22.1-22.1h746.28c21.73 0 30.41-28.07 12.48-40.34L512.95 76.04a22.1 22.1 0 0 0-24.28-.45L81.04 333.02" />
        <path d="M539.2 520.02c-23.11-40.82-65.56-50.64-92.8-28.39-23.89 19.51-35.39 63.12-23.2 85.18 20.48 37.09 95.66-8.79 116 28.39 12.46 22.79-.81 67.35-23.2 85.18-30 23.89-85.14 6.77-116-56.79M480 752V432" />
      </g>
    </Wrapper>
  )
}

export default DevBankIcon
