import styled from 'styled-components'

export const LineChartContainer = styled.div`
  background: #ffffff;
  margin-left: -80px;
  margin-right: -30px;
`

export const VerticalLine = styled.div<{ selectedYear: boolean }>`
  height: 10px;
  font-weight: 500;
  margin-bottom: 4px;
  color: ${(props) => (props.selectedYear ? `#000000` : '#cccccc')};
`

export const YearFilter = styled.span<{ selectedYear: boolean }>`
  color: #cccccc;
  font-size: 12px;
  color: ${(props) => (props.selectedYear ? `#000000` : '#cccccc')};
  font-family: 'Montserrat', serif;
  font-weight: 600;
`

export const YearsContainer = styled.div<{ items: number }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: -12px;
  /* ${(props) => props.items && `left: calc(50% - ${props.items}%);`} */
`

export const ActiveYear = styled.span`
  background-color: #000000;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  top: 24%;
  left: 0;
  right: 0;
  margin: 0 auto;
`

export const HorizontalScrollerDiv = styled.div`
  @media (max-width: 880px) {
    overflow-x: scroll;
    margin-right: -28px;
    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
  }
`
