import { Tabs } from 'antd'

import {
  AGMHistory,
  CompanyInfo,
  DividendHistory,
  Events,
  Financials,
  News,
  RightShareHistory,
  TabLabel,
} from './tab-components'

const tickerItems = [
  {
    label: <TabLabel label="Company Info" />,
    key: '1',
    children: <CompanyInfo />,
  },
  // {
  //   label: <TabLabel label="Basic Chart" />,
  //   key: '2',
  //   children: <BasicChart />,
  // },
  {
    label: <TabLabel label="News" />,
    key: '3',
    children: <News />,
  },
  {
    label: <TabLabel label="Events" />,
    key: '4',
    children: <Events />,
  },
  // {
  //   label: <TabLabel label="Announcement" />,
  //   key: '5',
  //   children: '',
  // },
  // {
  //   label: <TabLabel label="Financial Reports" />,
  //   key: '6',
  //   children: <FinancialReports />,
  // },
  // {
  //   label: <TabLabel label="Floorsheet" />,
  //   key: '7',
  //   children: '',
  // },
  // {
  //   label: <TabLabel label="Price History" />,
  //   key: '8',
  //   children: '',
  // },
  {
    label: <TabLabel label="Financials" />,
    key: '9',
    children: <Financials />,
  },
  // {
  //   label: <TabLabel label="Analysis" />,
  //   key: '10',
  //   children: '',
  // },
  // {
  //   label: <TabLabel label="Auction" />,
  //   key: '11',
  //   children: <AuctionHistory />,
  // },
  {
    label: <TabLabel label="Dividend History" />,
    key: '12',
    children: <DividendHistory />,
  },
  {
    label: <TabLabel label="AGM History" />,
    key: '13',
    children: <AGMHistory />,
  },
  {
    label: <TabLabel label="Right Share History" />,
    key: '14',
    children: <RightShareHistory />,
  },
]

const TickerTabs = () => {
  return (
    <div className="ticker-tab">
      <Tabs tabPosition="left" items={tickerItems} className="hidden lg:flex" />
      <Tabs tabPosition="top" items={tickerItems} className="flex lg:hidden" />
    </div>
  )
}

export default TickerTabs
