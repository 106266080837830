import PageHeader from 'components/page-header'
import CustomTabs from './features/custom-tabs'

const FAQs = () => {
  return (
    <div>
      <PageHeader heading="Got any Questions?" title="FAQs" />
      <CustomTabs />
    </div>
  )
}

export default FAQs
