import { Button as AntDButton } from 'antd'

export type ButtonProps = {
  type: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed' | undefined
  label: string
  htmlType?: 'button' | 'submit' | 'reset'
  loading?: boolean
  disabled?: boolean
  extraClass?: string
  icon?: any
  onClick?: any
  onSubmit?: any
}

export default function Button(props: ButtonProps) {
  return (
    <AntDButton
      className={`${props.extraClass}`}
      type={props.type}
      htmlType={props.htmlType || 'button'}
      onClick={props.onClick}
      onSubmit={props.onSubmit}
      loading={props.loading}>
      {props.label}
      {props.loading}
      {props.icon && (
        <span className="block" {...props}>
          {' '}
          {props.icon}{' '}
        </span>
      )}
    </AntDButton>
  )
}
