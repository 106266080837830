import React from 'react'

import { getFaqs } from 'services/FaqsService'
import Button from 'ui/Button'
import TableIcon from 'ui/Icons/TableIcon'
import FaqTab from '../faq-tab'
import TabsDrawer from './tabs-drawer'
import TabsList from './tabs-list'
import { FaqsDataType } from './_types'

const CustomTabs = () => {
  const [subCategory, setSubCategory] = React.useState<string>(
    // 'where is bottle located',
    ''
  )
  const [openDrawer, setOpenDrawer] = React.useState(false)

  const [faqs, setFaqs] = React.useState<FaqsDataType>([])

  React.useEffect(() => {
    const fetchData = async () => {
      const resp: any = await getFaqs()
      setFaqs(resp.results)
    }

    fetchData()
  }, [getFaqs])

  //   Creating a separate array of sub-categories for sending it as data for the FAQs Tab, so that one level of nesting is removed
  const subCategoryData = faqs?.reduce((temp, init) => {
    temp.push(...init.sub_categories)
    return temp
  }, [] as any)

  return (
    <>
      <div className="px-6 py-10 lg:px-15 lg:py-20 text-left bg-white">
        {/* Button that opens up the drawer of tabs list in the medium to small screens */}
        <div className="sticky top-0 z-50 bg-white py-3 lg:hidden">
          <Button
            // label="Tabs List"
            label={subCategory}
            type="primary"
            icon={<TableIcon className="text-white h-6 w-6 mr-3" />}
            extraClass="flex flex-row-reverse items-center justify-center uppercase text-xl h-12 w-auto"
            onClick={() => setOpenDrawer(true)}
          />
        </div>
        <div className="grid grid-cols-12 justify-items-start">
          <TabsList
            subCategory={subCategory}
            setSubCategory={setSubCategory}
            faqs={faqs}
            extraClass="hidden lg:block col-span-3"
          />
          <div className="col-span-12 lg:col-span-9 justify-self-start pt-3 w-full">
            {/* Sub category as a heading for a better user experience */}
            {/* <h3 className="lg:hidden capitalize font-secondary font-medium text-2xl text-center mb-2 underline">
              {subCategory}
            </h3> */}
            <FaqTab data={subCategoryData} category={subCategory} />
          </div>
        </div>
      </div>
      <TabsDrawer
        openDrawer={openDrawer}
        handleClose={() => setOpenDrawer(false)}>
        <TabsList
          subCategory={subCategory}
          setSubCategory={setSubCategory}
          faqs={faqs}
          setOpenDrawer={setOpenDrawer}
        />
      </TabsDrawer>
    </>
  )
}

export default CustomTabs
