import React from 'react'
import { Carousel } from 'antd'

import { LeftGradient, MidGradient, RightGradient } from 'components/header'
import { getBannerList } from 'services/HomeServices'
import { BannerDataTypes } from '../_types'
import BannerContent from '../banner-content'

const BannerCarousel = () => {
  const [banners, setBanners] = React.useState<BannerDataTypes>()

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getBannerList()
        setBanners(resp)
      } catch (e) {
        console.log({ e })
      }
    }

    fetchData()
  }, [getBannerList])

  return (
    <div className="!font-primary mt-10 sm:mt-28 2xl:mt-48 mb-10 sm:mb-25 md:mb-50 mx-auto lg:h-[65vh] xl:h-[60vh]">
      <Carousel autoplay autoplaySpeed={5000}>
        {banners?.map((banner) => (
          <BannerContent
            key={banner.id}
            title={banner.title}
            subTitle={banner.sub_title}
            buttonLabel={banner.button_name}
            buttonUrl={banner.button_url}
          />
        ))}
      </Carousel>
      <LeftGradient
        style={{ height: '70%', filter: 'blur(260px)', top: '25%' }}
      />
      <MidGradient
        style={{ height: '70%', filter: 'blur(260px)', top: '25%' }}
      />
      <RightGradient
        style={{ height: '70%', filter: 'blur(260px)', top: '25%' }}
      />
    </div>
  )
}

export default BannerCarousel
