import PageHeader from 'components/page-header'
import { WhyAbc } from 'pages/Services/features'
import ServicesList from './features/services-list'

const Services = () => {
  return (
    <div className="">
      <PageHeader
        title="Get New Solution"
        heading="A licensed Depository Participants with ID 13200">
        <PageHeader.Description>
          The basic challenge in our Nepalese market is to find the right place
          to invest in. One of the right places to invest your money for the
          future returns is the stock market.
        </PageHeader.Description>
      </PageHeader>
      <ServicesList />
      <WhyAbc />
    </div>
  )
}

export default Services
