import React from 'react'
import { Tabs } from 'antd'
import styled from 'styled-components'

import { getFeesAndCommission } from 'services/HomeServices'
import ColoredHeading from 'ui/Text/ColorHeading'
import SectionHeading from 'ui/Text/SectionHeading'

import { FeesAndCommissionTypes } from '../_types'
import TabContents from './tab-contents'

const customItems = (feesAndCommission: FeesAndCommissionTypes[]) =>
  feesAndCommission?.map((item) => {
    return {
      label: item.title,
      key: item.id.toString(),
      children: <TabContents feesAndCommission={item} />,
    }
  })

const FeesAndCommission = () => {
  const [feesAndCommission, setFeesAndCommission] = React.useState<
    FeesAndCommissionTypes[]
  >([])

  React.useEffect(() => {
    const fetchData = async () => {
      const resp: any = await getFeesAndCommission()
      setFeesAndCommission(resp.results)
    }
    fetchData()
  }, [getFeesAndCommission])

  return (
    <div className="relative overflow-hidden sm:py-22.5 sm:px-16 py-12 px-8 pt-20 pb-8">
      <LeftGradient />
      <RightGradient />
      <BottomGradient />
      <div className="flex flex-col justify-center">
        <ColoredHeading label="Fees And Commission" />
        <SectionHeading label="We provide the following services for respective fees" />
      </div>
      <div className="horizontal-tab mt-10">
        <Tabs
          defaultActiveKey={feesAndCommission[0]?.id.toString()}
          className="horizontal"
          items={customItems(feesAndCommission)}
        />
      </div>
    </div>
  )
}

export default FeesAndCommission

const LeftGradient = styled.div`
  position: absolute;
  left: 0%;
  width: 50%;
  background: #c9bbff;
  filter: blur(1500px);
  height: 74%;
  border-radius: 50%;
  z-index: -1;
`
const RightGradient = styled.div`
  position: absolute;
  right: 0%;
  width: 50%;
  background: #faffbb;
  filter: blur(300px);
  height: 74%;
  border-radius: 50%;
  z-index: -1;
`
const BottomGradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 80%;
  background: #feb8b4;
  filter: blur(130px);
  height: 20%;
  z-index: -1;
  border-radius: 50%;
  mix-blend-mode: normal;
`
