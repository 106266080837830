import Wrapper from './Wrapper'

const OthersIcon = ({ className }: { className?: string }) => {
  return (
    <Wrapper>
      <path
        d="M432 672c0-119.29-136-169.31-136-240s96.71-128 216-128 216 57.31 216 128-136 120.71-136 240M272 342.63c-70.69 0-128-35.82-128-80 0-30.59 27.48-57.17 67.85-70.63C199.2 203.46 192 216.62 192 230.63c0 44.18 71.63 80 160 80 7.95 0 15.77-.29 23.41-.85M752 342.63c70.69 0 128-35.82 128-80 0-30.59-27.48-57.17-67.85-70.63C824.8 203.46 832 216.62 832 230.63c0 44.18-71.63 80-160 80-7.95 0-15.77-.29-23.41-.85M392 424l72 24M640 416l-64 32M480 720v48M544 720v48M483.57 688h56.85c37.3 0 67.57 30.28 67.57 67.57 0 24.52-19.91 44.43-44.43 44.43H460.41c-24.52 0-44.43-19.91-44.43-44.43 0-37.3 30.28-67.57 67.57-67.57Z"
        className={className}
      />
    </Wrapper>
  )
}

export default OthersIcon
