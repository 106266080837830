import parser from 'react-html-parser'

import { FeesAndCommissionTypes } from '../../_types'

type TabContentsProps = {
  feesAndCommission: FeesAndCommissionTypes
}

const TabContents = ({ feesAndCommission }: TabContentsProps) => {
  return (
    <div className="flex justify-evenly items-center py-10">
      <img
        src={feesAndCommission.image}
        alt={feesAndCommission.title}
        className="hidden xl:block"
      />
      <div className="fees-and-commission-contents text-lg text-left font-primary ">
        {parser(feesAndCommission.description)}
      </div>
    </div>
  )
}

export default TabContents
