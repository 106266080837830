import { AboutOurCompany, Timeline, OurMission } from 'pages/AboutUs/features'

const AboutUs: React.FC = () => {
  return (
    <div>
      <AboutOurCompany />
      <OurMission />
      <Timeline />
    </div>
  )
}

export default AboutUs
