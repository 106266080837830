import './App.css'
import './index.css'

import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import ABCRoutes from 'routes'

function App() {
  return (
    <div className="App">
      <ABCRoutes />
      <ScrollToTop />
    </div>
  )
}

export default App
