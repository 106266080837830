import { Drawer as AntDrawer } from 'antd'

const TabsDrawer = ({ openDrawer, handleClose, children }: any) => {
  return (
    <AntDrawer
      open={openDrawer}
      onClose={handleClose}
      closable={false}
      placement="left"
      width="70vw"
      className="!p-0">
      <>
        <div className="flex justify-between mb-10">
          <span></span>
          <button
            className="flex font-bold text-5xl w-10 h-9"
            onClick={handleClose}>
            <img src="/images/plus.svg" className="rotate-315 w-6 h-6" />
          </button>
        </div>
        {children}
      </>
    </AntDrawer>
  )
}

export default TabsDrawer
