import { useState, useEffect } from 'react'
import { useParams } from 'react-router'

import {
  BlogHeading,
  BlogContent,
  RelatedPosts,
} from 'pages/BlogDetail/features'
import { imageContainer, policiesContainer } from 'components/footer/styles'
import PageHeader from 'components/page-header'
import { getBlogDetail } from 'services/BlogService'

const BlogDetail: React.FC = () => {
  const [blogDetail, setBlogDetail] = useState<any>({
    id: 0,
    title: '',
    title_image: '',
    created_at: '',
    body: '',
    author: '',
  })
  const { id } = useParams()
  useEffect(() => {
    const fetchData = async () => {
      const data = await getBlogDetail(id)
      setBlogDetail(data)
    }
    const result = fetchData()
  }, [])
  return (
    <>
      <PageHeader title="Our Blogs" heading="Business Updates"></PageHeader>
      <div className="bg-white pt-20 relative">
        <div className={imageContainer}>
          <img
            src={blogDetail.title_image}
            alt={blogDetail.title}
            className="w-full"
          />
        </div>
        <div className={`${policiesContainer} sm:mt-0 pt-5`}>
          <BlogHeading
            createdAt={blogDetail.created_at}
            author={blogDetail.author}
          />
          <BlogContent
            body={blogDetail.body}
            title={blogDetail.title}
            category={blogDetail.category}
          />
          <hr className="my-15 bg-lightGrey h-[2px]" />
          <RelatedPosts category={blogDetail.category} />
        </div>
      </div>
    </>
  )
}

export default BlogDetail
