import { useEffect, useState } from 'react'

import { useWindowDimensions } from 'hooks'
import { LineChartWithTooltip as LineChart } from 'components/charts'
import Recap from 'components/recap'
import LeadsCard from '../leads-card'
import { CustomTooltipProps, RecapData } from './_types'
import Tooltip from './Tooltip'
import {
  aboutRecapDataFormatter,
  initialRecapData,
} from 'utils/recapDataFormatter'

const CustomTooltip = ({ graphData, tooltipData }: CustomTooltipProps) => {
  const [recapData, setRecapData] = useState<RecapData[]>(initialRecapData)
  useEffect(() => {
    if (tooltipData) {
      let formattedData = aboutRecapDataFormatter(tooltipData)
      setRecapData(formattedData)
    }
  }, [tooltipData])

  const screenDimension = useWindowDimensions()
  return (
    <div className="w-full col-span-9 450:col-span-10 md:col-span-11">
      <div className="grid lg:grid-cols-6 justify-items-center justify-center items-start gap-y-5 lg:gap-y-2 1.5xl:grid-cols-8 1.5xl:justify-between 1.5xl:gap-y-0 bg-white border-2 border-solid border-black py-2 px-3 sm:py-4 sm:px-6 mb-4 rounded-xl">
        <div className="flex flex-col 450:flex-row 450:items-center 450:justify-center md:justify-start col-span-3 1.5xl:col-span-3">
          <span className="font-bold font-primary text-center text-base 500:text-xl leading-[20px] mb-2 450:mb-0 mr-2 500:mr-5 xl:mr-10">
            NEPSE
          </span>
          <div className="grid grid-cols-2 gap-2 450:flex w-3/25">
            {recapData.slice(0, 4).map(({ subHeading, subValue, rise }, i) => (
              <Recap
                key={subHeading}
                subHeading={subHeading}
                subValue={subValue}
                fullBorder={i % 2 !== 0}
                rise={rise}
                lastChild={i === 3}
                about
              />
            ))}
          </div>
        </div>
        <div className="order-first lg:order-1 col-start-1 lg:col-start-3 justify-self-center 1.5xl:order-none 500:mx-10 col-span-2 1.5xl:col-span-3">
          <LineChart
            width={
              screenDimension.width > 449 ? 325 : screenDimension.width - 180
            }
            height={40}
            data={graphData}
            strokeWidth={4}
            dark
            tooltip={Tooltip}
          />
        </div>
        <div className="flex flex-col 450:flex-row items-center justify-self-center lg:justify-self-end col-span-3 1.5xl:col-span-2">
          <span className="font-bold font-primary text-base 500:text-xl leading-[20px] mb-2 450:mb-0 mr-2 500:mr-5 xl:mr-10">
            ABC
          </span>
          <div className="flex">
            {recapData.slice(4, 8).map(({ subHeading, subValue, rise }, i) => (
              <Recap
                key={i}
                subHeading={subHeading}
                subValue={subValue}
                rise={rise}
                about
              />
            ))}
          </div>
        </div>
      </div>
      <div className="grid gap-4 justify-center xl:grid-cols-2 max-h-[308px] overflow-x-auto">
        {tooltipData?.event.map((recap) => {
          return (
            <LeadsCard key={recap.id} body={recap.body} image={recap.image} />
          )
        })}
      </div>
    </div>
  )
}

export default CustomTooltip
