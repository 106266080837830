import { RecapBlockProps } from "../../_types"

const RecapBlock = ({ title, value }: RecapBlockProps) => {
    return (
      <div className="flex flex-col items-start md:gap-3 mr-2">
        <h5 className="uppercase text-xs">{title}:</h5>
        <h6 className="text-base font-bold">{value}</h6>
      </div>
    )
  }

  export default RecapBlock