import axios from 'axios'

import {
  BalanceSheetQuarter,
  IncomeStatementQuarter,
  KeyStatsQuarter,
} from '../pages/TickerProfile/_types'

const base_url = process.env.REACT_APP_BASE_URL_NEPSE

export const getShareDetail = async (
  symbol: string | undefined,
): Promise<any[]> => {
  const res = await axios.get(
    `https://6d5080nefi.execute-api.ap-south-1.amazonaws.com/dev/api/share/market-data/live/${symbol}/detail`,
  )
  const resData = await res.data.data
  return resData
}

export const getNews = async (
  symbol: string | undefined,
): Promise<any> => {
  const res = await axios.get(
    `https://6d5080nefi.execute-api.ap-south-1.amazonaws.com/dev/api/share/market-data/news/${symbol}/detail`,
  )
  const resData = await res.data.data
  return resData
}

export const getAuctionHistory = async (): Promise<any> => {
  const res = await axios.get(
    'https://6d5080nefi.execute-api.ap-south-1.amazonaws.com/dev/api/auction/list',
  )
  const resData = await res.data.data
  return resData
}

export const getRightShare = async (): Promise<any> => {
  const res = await axios.get(
    'https://6d5080nefi.execute-api.ap-south-1.amazonaws.com/dev/api/rightshare/list',
  )
  const resData = await res.data.data
  return resData
}

export const getDividentHistory = async (
  symbol: string | undefined,
): Promise<any> => {
  const res = await axios.get(
     `${base_url}dividend-history/${symbol}`,
  )
  const resData = await res.data.response
  return resData
}

export const getRightShareHistory = async (
  symbol: string | undefined,
): Promise<any> => {
  const res = await axios.get(
     `${base_url}right-share-history/${symbol}`,
  )
  const resData = await res.data.response
  return resData
}

export const getAGMHistory = async (
  symbol: string | undefined,
): Promise<any> => {
  const res = await axios.get(
     `${base_url}agm-history/${symbol}`,
  )
  const resData = await res.data.response
  return resData
}

export const getEventHistory = async (
  symbol: string | undefined,
): Promise<any> => {
  const res = await axios.get(
     `${base_url}events-announcements/${symbol}`,
  )
  const resData = await res.data.response
  return resData
}

export const getBalanceSheetQuarter = async (
  ticker: string | undefined,
  quarter: string | undefined,
): Promise<any> => {
  const res = await axios.get(
    `${base_url}financials/balance-sheet/${ticker}?quarter=${quarter}`,
  )
  const resData = await res.data.response
  return resData
}

export const getIncomeStatementQuarter = async (
  ticker: string | undefined,
  quarter: string | undefined,
): Promise<any> => {
  const res = await axios.get(
    `${base_url}financials/income-statement/${ticker}?quarter=${quarter}`,
  )
  const resData = await res.data.response
  return resData
}

export const getKeyStatsQuarter = async (
  ticker: string | undefined,
  quarter: string | undefined,
): Promise<any> => {
  const res = await axios.get(
    `${base_url}financials/key-stats/${ticker}?quarter=${quarter}`,
  )
  const resData = await res.data.response
  return resData
}




export const getBalanceSheet = async (
  ticker: string | undefined,
): Promise<[BalanceSheetQuarter, BalanceSheetQuarter, BalanceSheetQuarter]> => {
  const [
    balanceSheetCurrentQuarter,
    balanceSheetPreviousQuarter,
    balanceSheetPreviousYearQuarter,
  ] = await Promise.all([
    getBalanceSheetQuarter(ticker, 'current'),
    getBalanceSheetQuarter(ticker, 'previous'),
    getBalanceSheetQuarter(ticker, 'previous-year'),
  ]);
  return [
    balanceSheetCurrentQuarter,
    balanceSheetPreviousQuarter,
    balanceSheetPreviousYearQuarter,
  ];
};

export const getIncomeStatement = async (
  ticker: string | undefined,
): Promise<[IncomeStatementQuarter, IncomeStatementQuarter, IncomeStatementQuarter]> => {
  const [
    incomeCurrentQuarter,
    incomePreviousQuarter,
    incomePreviousYearQuarter,
  ] = await Promise.all([
    getIncomeStatementQuarter(ticker, 'current'),
    getIncomeStatementQuarter(ticker, 'previous'),
    getIncomeStatementQuarter(ticker, 'previous-year'),
  ]);
  return [
    incomeCurrentQuarter,
    incomePreviousQuarter,
    incomePreviousYearQuarter,
  ];
};

export const getKeyStats = async (
  ticker: string | undefined,
): Promise<[KeyStatsQuarter, KeyStatsQuarter, KeyStatsQuarter]> => {
  const [
    keyStatsCurrentQuarter,
    keyStatsPreviousQuarter,
    keyStatsPreviousYearQuarter,
  ] = await Promise.all([
    getKeyStatsQuarter(ticker, 'current'),
    getKeyStatsQuarter(ticker, 'previous'),
    getKeyStatsQuarter(ticker, 'previous-year'),
  ]);
  return [
    keyStatsCurrentQuarter,
    keyStatsPreviousQuarter,
    keyStatsPreviousYearQuarter,
  ];
};

