import axiosInstance from './axios'

export const getJobs = async (): Promise<any[]> => {
  const res = await axiosInstance.get('/career/api/career-blogs/')
  const resData = await res.data
  return resData
}

export const postCv = async (data: any): Promise<any> => {
  const res = await axiosInstance.post(
    '/career/api/cv/send',
    { ...data },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  )

  return res
}
