import axiosInstance from './axios'

export const postMessageContact = async (data: {
  name: string
  message: string
  email: string
}) => {
  try {
    const res = await axiosInstance.post('/career/api/create/contactus', {
      ...data,
    })
    return res
  } catch (err: any) {
    return err.response
  }
}
