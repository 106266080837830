import React from 'react'
import styled from 'styled-components'

import ColoredHeading from 'ui/Text/ColorHeading'
import LineChartTimeLine from '../line-chart'

const Timeline: React.FC = () => {
  return (
    <div className="overflow-hidden">
      <div className="items-start sm:py-22.5 sm:px-16 py-12 px-8 pt-20 pb-8 bg-white">
        <ColoredHeading label="NEPSE's JOURNEY" extraClass="flex mb-4" />
        <HighlightAbout>Performance and Major&nbsp;Events</HighlightAbout>
        <HighlightAbout>during the period</HighlightAbout>
        <LineChartTimeLine />
      </div>
    </div>
  )
}

const HighlightAbout = styled.h3`
  color: #000000;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`

export default Timeline
