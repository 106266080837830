import React, { useEffect, useState } from 'react'
import { getPageBySlug } from 'services/PageService'
import parse from 'react-html-parser'

type PageContent = {
  id: number
  title: string
  slug: string
  body: string
}

const DynamicPage = ({ pageSlug }: { pageSlug: string }) => {
  const [pageDetail, setPageDetail] = useState<PageContent>()

  useEffect(() => {
    getPageBySlug(pageSlug)
      .then((data) => setPageDetail(data))
      .catch((err) => console.log(err?.message))
  }, [pageSlug])

  return (
    <div className="dp px-7.5 md:px-15 text-left ">
      <div> {parse(pageDetail?.body || '')}</div>
  
    </div>
  )
}

export default DynamicPage
