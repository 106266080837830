import PageHeader from 'components/page-header'
import {
  ContactForm,
  ContactInformation,
  FindOurOffice,
} from 'pages/Contact/features'

const Contact = () => {
  return (
    <div>
      <PageHeader heading="Get In Touch" title="Contact Information" />
      <div className="grid gap-y-10 1100:grid-cols-10 bg-white py-10 md:pt-20 md:py-0 px-5 500:px-10 md:px-15">
        <ContactInformation />
        <ContactForm />
      </div>
      <FindOurOffice />
    </div>
  )
}

export default Contact
