import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import {
  FeesAndCommission,
  OurFeatures,
  WhyChooseUs,
  LetsGrowTogether,
  Timeline,
  BannerCarousel,
} from 'pages/StepOne/features'
import Slider from 'components/slider'

const StepOne: React.FC = () => {
  useEffect(() => {
    //check for img or video content here and set it in state
  }, [])

  const [imgBody, setImgBody] = useState('')

  return (
    <>
      {/* Display Img/video or Step 1 */}
      <BannerCarousel />
      <Timeline />
      <WhyChooseUs />
      <OurFeatures />
      <FeesAndCommission />
      <LetsGrowTogether />
      <Slider />
    </>
  )
}

const ImageBackground = styled.img`
  height: 100%;
  width: 100%;
`

export default StepOne
