export const menuItems = [

  {
    title: 'Company',
    subItems: [
      {
        name: 'About',
        href: '/about',
      },
      {
        name: 'Services',
        href: '/services'
      },
      {
        name: 'Blogs',
        href: '/blogs',
      },
      {
        name: 'Careers',
        href: '/careers',
      },
      {
        name: 'FAQs',
        href: '/faqs',
      },
    ],
  },
  {
    title: 'Support',
    subItems: [
      {
        name: 'Contact',
        href: '/contact',
      },
      {
        name: 'Offline Downloads',
        href: '/offline-downloads',
      },
    ],
  },

  {
    title: 'Social Media',
    subItems: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/abcsecurities17',
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/abcsecurities/',
      },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/abc-securities/',
      },
    ],
  },
  {
    title: 'Useful Links',
    subItems: [
      {
        name: 'TMS',
        href: 'https://tms17.nepsetms.com.np/',
      },
      {
        name: 'MeroShare',
        href: 'https://meroshare.cdsc.com.np/',
      },
      {
        name: 'NEPSE',
        href: 'https://www.nepalstock.com.np/',
      },
      {
        name: 'CDSC',
        href: 'https://www.cdsc.com.np/',
      },
      {
        name: 'SEBON',
        href: 'https://www.sebon.gov.np/',
      },
    ],
  }
]
