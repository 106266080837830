import axios from 'axios'
import { TimelineData } from 'pages/StepOne/features/line-chart/types'

import axiosInstance from './axios'

export const getWhyChooseUs = async (): Promise<any[]> => {
  const res = await axiosInstance.get('/dashboard/api/v1/why-choose-us/list')
  const resData = await res.data
  return resData
}

export const getFeesAndCommission = async (): Promise<any[]> => {
  const res = await axiosInstance.get('/dashboard/api/v1/fees-commission/list')
  const resData = await res.data
  return resData
}

export const getOurFeatures = async (): Promise<any[]> => {
  const res = await axiosInstance.get('/dashboard/api/v1/our-feature/list')
  const resData = await res.data
  return resData
}
export const getTimeline = async (): Promise<TimelineData[]> => {
  const res = await axiosInstance.get('/share/history/list/all')
  console.log(res, "getTimeline")
  const resData = await res.data
  console.log(resData, "getTimeline")
  return resData
}

export const postEmail = async (email: { email: string }): Promise<any[]> => {
  const res: any = await axiosInstance.post(
    '/newsletter/api/list-create',
    email,
  )
  return res
}

export const getBannerList = async (): Promise<any[]> => {
  const res: any = await axiosInstance.get('/dashboard/banner/api/list')
  const resData = await res.data.results
  return resData
}

// Ticker Profile Service
export const getCompanyInfo = async (): Promise<any[]> => {
  const res: any = await axios.get(
    'https://6d5080nefi.execute-api.ap-south-1.amazonaws.com/dev/api/share/companies/list',
  )
  const resData = await res.data.data
  return resData
}

// Indices(Sector-wise) Service
export const getSectorList = async (): Promise<any[]> => {
  const res: any = await axios.get(
    'https://6d5080nefi.execute-api.ap-south-1.amazonaws.com/dev/api/share/sector/list',
  )
  const resData = await res.data.data
  return resData
}

export const getGainersList = async (): Promise<any[]> => {
  const res: any = await axios.get(
    'https://6d5080nefi.execute-api.ap-south-1.amazonaws.com/dev/api/share/top-gainers/list',
  )
  const resData = await res.data.data
  return resData
}

export const getLosersList = async (): Promise<any[]> => {
  const res: any = await axios.get(
    'https://6d5080nefi.execute-api.ap-south-1.amazonaws.com/dev/api/share/top-loser/list',
  )
  const resData = await res.data.data
  return resData
}
