import Wrapper from './Wrapper'

const ManufactureIcon = ({ className }: { className?: string }) => {
  return (
    <Wrapper>
      {/* <path d="M960 944H104.19c-12.21 0-22.1-9.89-22.1-22.1v-22.1c0-12.21 9.89-22.1 22.1-22.1H833.5c12.21 0 22.1-9.89 22.1-22.1L854.56 160c0-12.21-9.89-22.1-22.1-22.1h-47.39c-10.45 0-18.92 8.47-18.92 18.92V812.2c0 12.06-9.8 21.82-21.85 21.79-203.78-.64-407.56-1.28-611.34-1.91-11.58-.04-20.96-9.44-20.96-21.02V407.3c0-16.79 18.71-26.81 32.68-17.49l126.63 84.42c13.97 9.31 32.68-.7 32.68-17.49v-28.98c0-17.63 20.39-27.43 34.16-16.42 30.56 24.45 61.13 48.9 91.69 73.35C443.6 495.7 464 485.9 464 468.27V348.18c0-17.7 20.54-27.48 34.28-16.32 73.91 60.05 147.81 120.1 221.72 180.15M240 576v176M400 576v176M560 576v176" /> */}
      <path d="M33.17,703.1h126.9V629.35H343.81v-58H226.23V703.1H487.8V546.2H293.29v44.65H458.88l-6.69-243.68H435.92l-6,263.56,104.84.36-7-295H511.84l-5.78,359.31,119.13.18v-167l-70.14.18-.18,195.59H702.19l-6-411.43H679.78l-5.6,364.79-27.12-.18-.9-52.42H766.73v20.06L721.18,625l.16-43.76a23.68,23.68,0,0,1,2.67-11c3-5.73,8.58-13.44,16.87-13.44h34.89s19.52,1.81,19.52,19.52V681.77h79v-30H720.81v52.6H991.34" />
    </Wrapper>
  )
}

export default ManufactureIcon
