import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactHTMLParser from 'react-html-parser'

export type BlogBodyProps = {
  body: string
  title: string
  category: string
}

const BlogContent = ({ body, title, category }: BlogBodyProps) => {
  return (
    <div className="mt-[25px]">
      <BlogTitle>{title}</BlogTitle>
      <Link to="/blog-category/trading">
        <BlogCategory>{category || 'Trading'}</BlogCategory>{' '}
      </Link>
      <BlogContents>{ReactHTMLParser(body)}</BlogContents>
    </div>
  )
}

const BlogTitle = styled.h3`
  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  text-align: left;
  @media (max-width: 769px) {
    font-size: 24px;
    line-height: inherit;
  }
`

const BlogCategory = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #0c9ff5;
  text-align: left;
  margin: 15px 0;
`

const BlogContents = styled.div`
  img {
    width: 714px;
    height: 328px;
    border-radius: 12px;
    float: left;
    margin-right: 20px;
  }
`

export default BlogContent
