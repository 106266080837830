import { Drawer as AntDrawer } from 'antd'

import CrossIcon from 'ui/Icons/CrossIcon'
import { DrawerProps, MenuItems } from './_types'

const menuItems: MenuItems[] = [
  {
    title: 'About',
    href: '/about',
  },
  {
    title: 'Services',
    href: '/services',
  },
  {
    title: 'Blogs',
    href: '/blogs',
  },
  {
    title: 'Careers',
    href: '/careers',
  },
  // {
  //   title: 'Opportunities',
  //   href: '/',
  // },
  {
    title: 'Contact',
    href: '/contact',
  },
  {
    title: 'FAQs',
    href: '/faqs',
  },
]

const Drawer = ({ openDrawer, handleClose }: DrawerProps) => {
  return (
    <AntDrawer
      open={openDrawer}
      onClose={handleClose}
      closable={false}
      width="100vw"
      className="text-left 500:text-center !p-0">
      <section className="flex justify-between">
        <span className="hidden 500:block"></span>
        <span className="uppercase font-medium text-2xl 3xl:text-4xl 500:px-5">
          Menu
        </span>
        <CrossIcon size={20} onClick={handleClose} />
      </section>
      <section className="py-6 500:py-12.5">
        <ul>
          {menuItems.map(({ title, href }: MenuItems) => (
            <a
              href={href}
              className="text-xl 3xl:text-3xl hover:text-gray-400 w-full px-3 py-2 my-2"
              key={title}>
              <li className="">{title}</li>
            </a>
          ))}
        </ul>
      </section>
    </AntDrawer>
  )
}

export default Drawer
