import {
  LineChart as RechartsLine,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { LineChartProps } from 'components/charts/_types'
import CustomTooltip from '../tooltip/CustomTooltip'

/* eslint-disable */
const LineChart = ({
  data,
  negative = false,
  nepse = false,
  dark = false,
  width = 118,
  height = 41.3,
  strokeWidth = 1,
  extraClass = '',
  origin = 0,
  tooltip,
}: LineChartProps & { tooltip?: typeof CustomTooltip }) => {
  return (
    <ResponsiveContainer
      width={width}
      height={height}
      className={`${extraClass}`}>
      <RechartsLine data={data} margin={{ top: 5, bottom: 5 }}>
        <XAxis dataKey="time" hide />
        <YAxis
          dataKey="stock_index"
          domain={[origin || 'dataMin', 'dataMax']}
          hide
        />
        <Line
          type="monotone"
          dataKey="stock_index"
          dot={false}
          stroke={
            negative
              ? '#FF452C'
              : nepse
              ? '#ffffff'
              : dark
              ? '#000000'
              : '#5CAD00'
          }
          strokeWidth={strokeWidth}
        />
        <Tooltip
          // contentStyle={{}}
          // itemStyle={{ color: 'black' }}
          content={!!tooltip ? tooltip : <CustomTooltip />}
        />
        <ReferenceLine
          y={origin}
          label=""
          stroke={
            negative
              ? '#FF452C'
              : nepse
              ? '#ffffff'
              : dark
              ? '#000000'
              : '#5CAD00'
          }
          strokeDasharray="3"
        />
      </RechartsLine>
    </ResponsiveContainer>
  )
}

export default LineChart
