const TriangleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="17"
      fill="none"
      className={className}>
      <path
        stroke="currentColor"
        stroke-width="4"
        d="M14.5 14.992 2.936 2h23.128L14.5 14.992Z"
      />
    </svg>
  )
}

export default TriangleIcon
