import { TimelineData } from 'pages/StepOne/features/line-chart/types'
import { initialTooltipData } from 'pages/StepOne/features/line-chart'
import { GraphData } from '../custom-tooltip/_types'
import { DataPointProps } from './_types'

const DataPoint = ({
  showTooltipYear,
  setShowTooltipYear,
  year,
  index,
  shareData,
  setToolTipData,
}: DataPointProps) => {
  return (
    <div className="relative cursor-pointer">
      <div
        onClick={() => {
          let graphPlots: GraphData[] = []
          setShowTooltipYear(year)
          let selectedYearData: TimelineData =
            shareData.find(
              (data: TimelineData) => data.year === parseInt(year),
            ) || initialTooltipData
          // Keeping this to change graph if required for future
          // shareData.map((shareData) => {
          //   graphPlots.push({
          //     year: shareData.year.toString(),
          //     stock: shareData.open,
          //   })
          // })
          // setGraphData(graphPlots)
          setToolTipData(selectedYearData)
        }}
        className={`bg-black w-1 h-4 hover:w-4 hover:h-4 hover:rounded-full  ${
          showTooltipYear === year ? 'w-4 h-4 rounded-full' : ''
        }
        `}></div>
      {showTooltipYear === year ? (
        <span className="absolute -left-12 -top-1 font-semibold font-monsterrat">
          {year}
        </span>
      ) : index % 3 == 0 ? (
        <span className="absolute -left-8 text-[10px] -top-1 font-light font font-monsterrat">
          {year}
        </span>
      ) : (
        <span className="absolute -left-6 text-[10px] -top-1 font-light font-monsterrat">
          {' '}
          -{' '}
        </span>
      )}
    </div>
  )
}

export default DataPoint
