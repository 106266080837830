import {
  useEffect,
  useState,
} from 'react'

import FeatureCard from 'components/feature-card'
import { getWhyChooseUs } from 'services/HomeServices'
import styled from 'styled-components'
import Button from 'ui/Button'
import ColoredHeading from 'ui/Text/ColorHeading'
import SectionHeading from 'ui/Text/SectionHeading'
import SubHeading from 'ui/Text/SubHeading'

import {
  whyChooseUsSectionContainer,
  whyChooseUsWrapper,
} from './styles'

const WhyChooseUs = () => {
  const [whyChooseUs, setWhyChooseUs] = useState<any>()

  useEffect(() => {
    const fetchData = async () => {
      const data = await getWhyChooseUs()
      setWhyChooseUs({ ...data })
      return data
    }
    const result = fetchData()
  }, [])

  return (
    <>
      <div className={whyChooseUsWrapper}>
        <LeftGradient />
        <RightGradient />
        <BottomGradient />
        <section className="flex flex-col">
          <ColoredHeading label="Personal Finance & ABC" />
          <SectionHeading
            label="Democratizing access to Capital Market for every Nepali around the globe."
            extraClass="normal-case"
          />
          <SubHeading label="ABC is driven to bring all the possible access with ease of use in priority to simplify Personal Finance, Investments and Value Gains, <strong>All&nbsp;Digitally</strong>." />
        </section>
        <section className={whyChooseUsSectionContainer}>
          {whyChooseUs?.results?.length > 0 ? (
            whyChooseUs.results.map(
              ({
                id,
                title,
                description,
                icon,
              }: {
                id: number
                title: string
                description: string
                icon: string
              }) => (
                <FeatureCard
                  key={id}
                  title={title}
                  description={description}
                  iconSrc={icon}
                  extraClass="col-span-2 lg:last-of-type:col-start-2 xl:last-of-type:col-start-5"
                />
              ),
            )
          ) : (
            <></>
          )}
        </section>
        <Button
          type="ghost"
          label={`Signup For Trading Account`}
          extraClass="font-medium text-base 500:text-2xl h-16"
          onClick={() => {
            window.open('https://kyc.abc.com.np', '_blank')
          }}
        />
      </div>
    </>
  )
}

export default WhyChooseUs

export const LeftGradient = styled.div`
  position: absolute;
  left: 0%;
  width: 60%;
  background: #ffbbbb;
  filter: blur(150px);
  height: 74%;
  z-index: -1;
`

export const RightGradient = styled.div`
  position: absolute;
  right: 0;
  width: 60%;
  background: #ffdcbb;
  filter: blur(150px);
  height: 74%;
  z-index: -1;
`
export const BottomGradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 80%;
  background: #befeb4;
  filter: blur(102px);
  height: 20%;
  z-index: -1;
  mix-blend-mode: normal;
`
