import Wrapper from './Wrapper'

const BankingIcon = () => {
  return (
    <Wrapper>
      <path d="M960 944H104.19c-12.21 0-22.1-9.89-22.1-22.1v-22.1c0-12.21 9.89-22.1 22.1-22.1H833.5c12.21 0 22.1-9.89 22.1-22.1l-1.04-423.57c0-12.21-9.89-22.1-22.1-22.1h-44.2c-12.21 0-22.1 9.89-22.1 22.1v379.86c0 12.21-9.89 22.1-22.1 22.1h-44.2c-12.21 0-22.1-9.89-22.1-22.1V432.03c0-12.21-9.89-22.1-22.1-22.1h-44.2c-12.21 0-22.1 9.89-22.1 22.1v379.86c0 12.21-9.89 22.1-22.1 22.1h-44.2c-12.21 0-22.1-9.89-22.1-22.1V432.03c0-12.21-9.89-22.1-22.1-22.1h-44.2c-12.21 0-22.1 9.89-22.1 22.1v379.86c0 12.21-9.89 22.1-22.1 22.1h-44.2c-12.21 0-22.1-9.89-22.1-22.1V432.03c0-12.21-9.89-22.1-22.1-22.1h-44.2c-12.21 0-22.1 9.89-22.1 22.1v379.86c0 12.21-9.89 22.1-22.1 22.1h-44.2c-12.21 0-22.1-9.89-22.1-22.1V432.03c0-12.21-9.89-22.1-22.1-22.1h-22.1c-12.21 0-22.1-9.89-22.1-22.1v-10.6c0-12.21 9.89-22.1 22.1-22.1h746.28c21.73 0 30.41-28.07 12.48-40.34L512.97 76.03a22.1 22.1 0 0 0-24.28-.45L81.04 333.02" />
    </Wrapper>
  )
}

export default BankingIcon
