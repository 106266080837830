import { Skeleton } from 'antd'

const MissionCardLoader: React.FC = () => {
  return (
    <div className="border-4 border-solid border-black p-3.75 max-w-[275px] rounded-xl cursor-pointer">
      <Skeleton.Image  active className="rounded-xl" />
      <div className="flex flex-col items-center mt-3.75">
        <Skeleton active />
      </div>
    </div>
  )
}

export default MissionCardLoader
