import Wrapper from './Wrapper'

const NonLifeInsuIcon = ({ className }: { className?: string }) => {
  return (
    <Wrapper>
      <path d="M656 822.96c-42.64 31.26-90.41 55.93-141.81 72.52C309.13 832.85 160 642.15 160 416.58c0-67.43 13.33-131.73 37.49-190.44 85.54 68.2 193.91 108.94 311.81 108.94s227.14-41.07 312.83-109.74c25 59.56 38.82 124.96 38.82 193.59S847.83 549.66 824.01 608" />
      <path d="M960 945.93H718.84c-18.22 0-33.13-14.91-33.13-33.13V768.77c0-18.22 14.91-33.13 33.13-33.13h144.03c18.22 0 33.13 14.91 33.13 33.13v143.22" />
      <path d="M771.41 630.51h38.89c18.28 0 33.13 14.84 33.13 33.13v72.02H738.29v-72.02c0-18.28 14.84-33.13 33.13-33.13ZM512 464v224M624 576H400" />
    </Wrapper>
  )
}

export default NonLifeInsuIcon
