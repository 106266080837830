export const officeInfos = [

    {
      office: 'Head Office',
      infos: [
        {
          data: 'Indrachowk-24, Kathmandu',
          icon: './images/location_vector.svg',
        },
        {
          data: '+977 01-5970117',
          icon: './images/phone.svg',
          href: 'tel:+977015970117'
        },
        {
          data: 'WhatsApp Support',
          icon: './images/whatsapp.svg',
          href: 'https://wa.me/+9779801016093'
        },
        {
          data: 'Viber Support',
          icon: './images/viber.svg',
          href: 'viber://chat/?number=%2B9779801016093'
        },
      ],
    },
    {
      office: 'Branch Office',
      infos: [
        {
          data: 'Prithivipath 19, Dharan-1 , Sunsari Nepal',
          icon: './images/location_vector.svg',
        },
        {
          data: '+977-25574083,+977-2537783',
          icon: './images/phone.svg',
          href: 'tel:+97725574083'
        },
        {
          data: 'WhatsApp Support',
          icon: './images/whatsapp.svg',
          href: 'https://wa.me/+9779801311083'
        },
        {
          data: 'Viber Support',
          icon: './images/viber.svg',
          href: 'viber://chat/?number=%2B9779801311083'
        },
      ],
    },
  ]
  
  export const openingHours = [
    {
      day: 'Sunday',
      time: '9:30 - 17:30',
    },
    {
      day: 'Monday',
      time: '9:30 - 17:30',
    },
    {
      day: 'Tuesday',
      time: '9:30 - 17:30',
    },
    {
      day: 'Wednesday',
      time: '9:30 - 17:30',
    },
    {
      day: 'Thursday',
      time: '9:30 - 17:30',
    },
    {
      day: 'Friday',
      time: '9:30 - 17:30',
    },
  ]