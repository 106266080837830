import { Badge } from 'antd'
import moment from 'moment'
import {
  AGMHistoryProps,
  DividendHistoryProps,
  EventHistoryProps,
  RightShareProps,
} from 'pages/TickerProfile/_types'

export const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (text: any) => <a>{text}</a>,
  },
  {
    title: 'Information',
    dataIndex: 'information',
    key: 'information',
  },
]

// Columns for the Auction history tab

export const auctionColumns = [
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    key: 'symbol',
  },
  {
    title: 'Auction Units',
    dataIndex: 'units',
    key: 'units',
  },
  {
    title: 'Opening Date',
    dataIndex: 'opening_date',
    key: 'opening_date',
  },
  {
    title: 'Closing Date',
    dataIndex: 'closing_date',
    key: 'closing_date',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: string) => (
      <Badge count={text} color={text === 'Closed' ? 'red' : 'green'}></Badge>
    ),
  },
  {
    title: 'Created Date',
    dataIndex: 'created_at',
    key: 'created_at',
  },
]

export const dividendColumns = [
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
  },
  {
    title: 'Bonus',
    dataIndex: 'bonus',
    key: 'bonus',
  },
  {
    title: 'Cash',
    dataIndex: 'cash',
    key: 'cash',
  },
  {
    title: 'Book Close',
    dataIndex: 'book_close',
    key: 'book_close',
  },
]

export const rightShareHistoryColumns = [
  {
    title: 'Bonus',
    dataIndex: 'bonus',
    key: 'bonus',
  },
  {
    title: 'Cash',
    dataIndex: 'cash',
    key: 'cash',
  },
  {
    title: 'Rights',
    dataIndex: 'rights',
    key: 'rights',
  },
  {
    title: 'Book Close',
    dataIndex: 'book_close',
    key: 'book_close',
  },
]

export const AGMHistoryColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 120
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
  },
  {
    title: 'Event Date',
    dataIndex: 'event_date',
    key: 'event_date',
    width: 120
  },
  {
    title: 'Agenda',
    dataIndex: 'agenda',
    key: 'agenda',
  },
]

export const eventHistoryColumns = [
  {
    title: 'Event Date',
    dataIndex: 'event_date',
    key: 'event_date',
    width: 120
  },
  {
    title: 'Agenda',
    dataIndex: 'agenda',
    key: 'agenda',
  },
]

export const balanceSheetColumns = 

   [
    {
      title: 'Quarter',
      dataIndex: 'quarter',
      key: 'quarter',
    },
    {
      title: 'Current (Q1)',
      dataIndex: 'current',
      key: 'current',
    },
    {
      title: 'Previous (Q4)',
      dataIndex: 'previous',
      key: 'previous',
    },
    {
      title: 'Previous Year (Q1)',
      dataIndex: 'previous_year',
      key: 'previous_year',
    },
  ]



export const getData = (currentData: any) => {
  return [
    {
      key: '1',
      title: 'Symbol',
      information: currentData?.symbol,
    },
    {
      key: '2',
      title: 'Company Name',
      information: currentData?.company_name,
    },
    {
      key: '3',
      title: 'Sector',
      information: currentData?.sector,
    },
    {
      key: '4',
      title: 'Listed Shares',
      information: currentData?.listed_shares,
    },
    {
      key: '5',
      title: 'Paidup Value',
      information: currentData?.paid_up_values,
    },
    {
      key: '6',
      title: 'Total Paidup Value',
      information: currentData?.total_paid_up_values,
    },
  ]
}

export const getRecapData = (shareDetails: any) => {
  return [
    {
      title: 'previous close',
      value: shareDetails?.pclose || 'N/A',
    },
    {
      title: 'open',
      value: shareDetails?.open || 'N/A',
    },
    {
      title: 'high',
      value: shareDetails?.high || 'N/A',
    },
    {
      title: 'low',
      value: shareDetails?.low || 'N/A',
    },
    {
      title: 'volume',
      value: shareDetails?.qty || 'N/A',
    },
  ]
}

export const getAuctionData = (auctionHistory: any) => {
  return auctionHistory?.map((auction: any, index: number) => ({
    key: index,
    symbol: auction.symbol,
    units: auction.units,
    opening_date: auction.opening_date,
    closing_date: auction.closing_date,
    status: auction.status,
    created_at: auction.created_at,
  }))
}

export const getDividendData = (dividendHistory: DividendHistoryProps) => {
  return dividendHistory?.map((dividend: any, index: number) => ({
    key: index,
    year: dividend.year,
    bonus: dividend.bonus,
    cash: dividend.cash,
    book_close: moment(dividend.book_close).format('DD-MM-YYYY'),
  }))
}

export const getRightShareData = (rightShareHistory: RightShareProps) => {
  return rightShareHistory?.map((rightShare: any, index: number) => ({
    key: index,
    bonus: rightShare.bonus,
    cash: rightShare.cash,
    rights: rightShare.rights,
    book_close: moment(rightShare.book_close).format('DD-MM-YYYY'),
  }))
}

export const getAGMData = (AGMHistory: AGMHistoryProps) => {
  return AGMHistory?.map((AGM: any, index: number) => ({
    key: index,
    date: moment(AGM.date).format('DD-MM-YYYY'),
    company: AGM.company,
    details: AGM.details,
    event_date: moment(AGM.event_date).format('DD-MM-YYYY'),
    agenda: AGM.agenda,
  }))
}


export const getEventData = (eventHistory: EventHistoryProps) => {
  return eventHistory?.map((event: any, index: number) => ({
    key: index,
    event_date: moment(event.date).format('DD-MM-YYYY'),
    agenda: event.agenda,
  }))
}


export const getBalanceSheetData = (financials: Array<any>) => {
  return financials?.map((financial: any, index: number) => ({
    key: index,
    quarter: financial.quarter,
    current: financial.q1,
    previous: financial.q2,
    previous_year: financial.q3,
  }))
}


