import React from 'react'
import { Table } from 'antd'
import { useParams } from 'react-router-dom'
import { rightShareHistoryColumns, getRightShareData } from 'constants/pages/ticker-profile'
import { getRightShareHistory } from 'services/CompanyProfileService'

const DividendHistory = () => {
  const [rightShareHistory, setRightShareHistory] = React.useState([])
  const data = getRightShareData(rightShareHistory)
  const tickerSymbol = useParams().ticker
  
  const fetchData = async () => {
    try {
      const resp = await getRightShareHistory(tickerSymbol)
      setRightShareHistory(resp)
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      <Table columns={rightShareHistoryColumns} dataSource={data} />
    </div>
  )
}

export default DividendHistory
