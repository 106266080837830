import React from 'react'
import { notification } from 'antd'

import { postMessageContact } from 'services/ContactService'
import Button from 'ui/Button'
import Field from 'ui/InputField'

const ContactForm = () => {
  const formInitialState = {
    name: '',
    email: '',
    message: '',
  }
  const [formData, setFormData] = React.useState(formInitialState)

  const [errorData, setErrorData] = React.useState(formInitialState)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    setErrorData({ ...errorData, [e.target.name]: false })
  }

  const openNotification = () => {
    notification.open({
      message: 'Submitted',
      description: 'Thank you, your message has been submitted.',
      onClick: () => {},
    })
  }

  return (
    <div className="order-first 1100:order-none col-span-12 1100:col-span-7">
      <h3 className="text-left font-secondary font-medium text-2xl leading-[48px]">
        Send us a message
      </h3>
      <form className="contact-form">
        <Field label="Name" type="text" id="name" error={errorData.name}>
          <Field.Input
            placeholder="John Appleseed"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Field>
        <Field label="Email" type="text" id="email" error={errorData.email}>
          <Field.Input
            placeholder="example@example.com"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Field>
        <Field
          label="Message"
          type="textfield"
          id="message"
          error={errorData.message}>
          <Field.TextArea
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Field>
        <Button
          type="primary"
          label="SUBMIT"
          extraClass="1100:block h-12 w-[260px] mt-7.5"
          onClick={async () => {
            const response = await postMessageContact(formData)
            const errorCopy = {
              email: '',
              name: '',
              message: '',
            }
            if (response.status == 400) {
              const { data } = response
              if (data.email.length > 0) errorCopy.email = data.email[0]
              if (data.message.length > 0) errorCopy.message = data.message[0]
              if (data.name.length > 0) errorCopy.name = data.name[0]
              setErrorData(errorCopy)
            }
            if (response.status == 201) {
              setFormData(formInitialState)
              openNotification()
            }
          }}
        />
      </form>
    </div>
  )
}

export default ContactForm
