import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

// import { LineChart } from 'components/charts'
import Modal from 'components/modal/index-modal'
// import { getGainersList, getLosersList } from 'services/HomeServices'
import LineChartTicker from 'components/charts/line-chart/LineChartTicker'
import {
  getIndicesDetail,
  getIndicesTimeline,
  getTopGainers,
  getTopLosers,
} from 'services/indicesService'
import { REFETCH_INTERVAL } from 'config/nepseData'

/* eslint-disable */
const GraphBlock = ({
  title = '',
  nepse = false,
  extraClass = '',
  slug,
}: {
  title: string
  slug: string
  nepse?: boolean
  extraClass?: string
}) => {
  const [showModal, setShowModal] = React.useState(false)
  const [gainers, setGainers] = React.useState<any[]>([])
  const [losers, setLosers] = React.useState<any[]>([])

  const [graphData, setGraphData] = React.useState<any[]>([])
  const [tickerDetail, setTickerDetail] = React.useState<any>({})

  const getData = useCallback(() => {
    getIndicesDetail(slug)
      .then((data) =>{ 
        console.log(data, 'data')
        console.log(data.percentage_change,'yuu')
        data.percentage_change = data.point_change > 0 ? data.percentage_change :  -Math.abs(data.percentage_change)
        console.log(data.percent_change, 'kkk')
        setTickerDetail(data)
      })
      .catch((err) => console.log(err))
    getIndicesTimeline(slug)
      .then((data) => setGraphData(data))
      .catch((err) => console.log(err))
  }, [slug])

  const getGainersAndLosers = useCallback(() => {
    getTopGainers(slug)
      .then((data) =>{ setGainers(data)})
      .catch((err) => console.log(err))
    getTopLosers(slug)
      .then((data) => setLosers(data))
      .catch((err) => console.log(err))
  }, [slug, showModal])

  React.useEffect(() => {
    getData()
    const handler = setInterval(getData, REFETCH_INTERVAL)
    return () => clearInterval(handler)
  }, [slug])

  // todo : move inside modal
  React.useEffect(() => {
    getGainersAndLosers()
    const handler = setInterval(
      () => showModal && getGainersAndLosers,
      REFETCH_INTERVAL,
    )
    return () => clearInterval(handler)
  }, [showModal])

  const fetchGainers = async () => {
    try {
      const resp = await getTopGainers(title)
      setGainers(resp)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchLosers = async () => {
    try {
      const resp = await getTopLosers(title)
      setLosers(resp)
    } catch (e) {
      console.log(e)
    }
  }
  const handleShowModal = () => {
    setShowModal(true)
    // fetchGainers()
    // fetchLosers()
  }

  const filterGainers = () => {
    return gainers.filter(
      (gainer) =>
        gainer.symbol === 'RSDC' ||
        gainer.symbol === 'CMF2' ||
        gainer.symbol === 'RURU' ||
        gainer.symbol === 'NICA',
    )
  }

  const filterLosers = () => {
    return losers.filter(
      (loser) =>
        loser.symbol === 'JALPA' ||
        loser.symbol === 'SMB' ||
        loser.symbol === 'NABIL' ||
        loser.symbol === 'BARUN',
    )
  }

  const isNegative = tickerDetail.point_change < 0
  console.log(tickerDetail?.percent_change, 'percentage_change')
  // console.log(title, 'title')
console.log(+tickerDetail?.latest_price - tickerDetail?.latest_price * tickerDetail?.percentage_change / 100, 'origin and title', title)
  return (
    <div
      className={`shrink-0 py-[5px] px-[7.5px] min-w-[120px] md:w-[205px] ${extraClass} ${
        nepse && isNegative
          ? 'bg-[#FF452C]'
          : nepse && !isNegative
          ? 'bg-[#5CAD00]'
          : ''
      }`}>
      <IndicesData
        nepse={nepse}
        className="!py-[5px] md:!py-[10px]"
        onClick={handleShowModal}>
        <h3
          className={`font-medium text-left text-sm drop-shadow-2xl ${
            nepse ? 'text-white' : ''
          }`}>
          {title}
        </h3>
        <div className="flex">
          <section className="flex flex-col mr-2.5">
            <span
              className={`font-bold text-md ${
                nepse
                  ? 'text-white'
                  : isNegative
                  ? 'text-[#FF452C]'
                  : 'text-[#5CAD00]'
              }`}>
              {tickerDetail?.latest_price}
            </span>
            <h4
              className={`text-left text-sm ${
                nepse
                  ? 'text-white'
                  : isNegative
                  ? 'text-[#FF452C]'
                  : 'text-[#5CAD00]'
              }`}>
              {/* {!negative ? `+${change}%` : change} */}
              {`${
                tickerDetail?.percentage_change
                  ? tickerDetail?.percentage_change + '%'
                  : '-'
              } `}
            </h4>
          </section>
          <LineChartTicker
            data={graphData}
            negative={isNegative}
            nepse={nepse}
            width={80}
            height={28}
            // height={80}
            origin={graphData[0]?.value}
            // origin={+tickerDetail?.latest_price - tickerDetail?.latest_price * tickerDetail?.percentage_change / 100}
            extraClass="hidden md:block mt-5"
          />
        </div>
      </IndicesData>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        percentage={tickerDetail?.percentage_change}
        title={title}
        // index_name={tickerDetail?.indices_name}
        index_name={title}
        index={tickerDetail?.latest_price}
        change={tickerDetail?.percentage_change}
        graphData={graphData?.map((item) => ({
          time: item?.timestamp,
          stock_index: item?.value,
        }))}
        negative={isNegative}
        gainers={gainers}
        losers={losers}
        tickerDetail={tickerDetail}
      />
    </div>
  )
}

type IndicesType = {
  nepse: boolean
}

const IndicesData = styled.div<IndicesType>`
  padding: 10px 8px;
  cursor: pointer;

  ${(props) =>
    !props.nepse &&
    css`
      &:hover {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
      }
    `}
`

export default GraphBlock
