import FooterMenu from 'components/footer-menu'
import PagesRoutes from 'constants/routes/pagesRoutes'
import {
  Link,
  useLocation,
} from 'react-router-dom'

import {
  linkToPages,
  policiesContainer,
  policiesDiv,
} from './styles'

const footItems = [
  {
    title: 'Privacy Policy',
    href: PagesRoutes.privacyPolicy,
    target: 'samePage',
  },
  {
    title: 'Terms & Conditions',
    href: PagesRoutes.termsAndConditions,
    target: 'samePage',
  },
  {
    title: 'Targeted Sanction List',
    href: 'https://moha.gov.np/page/targeted-sanction-list',
    target: 'blank',
  },
  {
    title: 'UNSC Consolidated List',
    href: 'https://www.un.org/securitycouncil/content/un-sc-consolidated-list',
    target: 'blank',
  },
]

const Footer = () => {
  const location = useLocation()
  return (
    <div className={policiesContainer}>
      <FooterMenu />
      <div className="text-center">
        <span className="leading-[26px] text-sm 500:leading-8 ">
          Stock Brokerage Services Licensed by Securities Board of Nepal (SEBON)
          Membership No. 21. Nepal Stock Exchange Ltd (NEPSE) Membership No. 17.
          Depository Participant (DP) Services Licensed by Securities Board of
          Nepal (SEBON) Membership No. 47. CDS & Clearing Ltd. (CDSCL) DPID
          13013200. Clearing Member (CM) Services Licensed by Nepal Stock
          Exchange Ltd. (NEPSE) & CDS & Clearing Ltd. (CDSCL).
        </span>
      </div>
      <div className={policiesDiv}>
        {footItems.map(({ title, href, target }) => (
          <Link
            to={href}
            key={title}
            className={`${linkToPages} text-sm`}
            target={target === 'blank' ? '_blank' : '_self'}>
            {title}
          </Link>
        ))}
      </div>
      {location?.pathname === '/' && <div className="pb-16" />}
    </div>
  )
}

export default Footer
