import React from 'react'

import ServiceBlock from 'pages/Services/features/service-block'
import { getOurServices } from 'services/ServicesService'
import { ServiceBlockProps } from '../_types'

const ServicesList = () => {
  const [services, setServices] = React.useState<ServiceBlockProps[]>([])

  React.useEffect(() => {
    const fetchData = async () => {
      const resp: any = await getOurServices()
      setServices(resp.results)
    }
    fetchData()
  }, [getOurServices])
  return (
    <div>
      {services?.map((service: ServiceBlockProps) => (
        <ServiceBlock
          title={service.title}
          subtitle={service.subtitle}
          description={service.description}
          icon={service.icon}
          key={service.id}
        />
      ))}
    </div>
  )
}

export default ServicesList
