// use the below as reference and write the same for events 
import React from 'react'
import { Table } from 'antd'
import { useParams } from 'react-router-dom'
import { eventHistoryColumns, getEventData } from 'constants/pages/ticker-profile'
import { getEventHistory } from 'services/CompanyProfileService'

const Events = () => {
    const [events, setEvents] = React.useState([])
    const data = getEventData(events)
    const tickerSymbol = useParams().ticker
    
    const fetchData = async () => {
        try {
        const resp = await getEventHistory(tickerSymbol)
        setEvents(resp)
        } catch (e) {
        console.log(e)
        }
    }
    
    React.useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
        <Table  columns={eventHistoryColumns} dataSource={data} />
        </div>
    )
}

export default Events