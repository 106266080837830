import React from 'react'
import { useParams } from 'react-router'
import { Table } from 'antd'

import { getCompanyInfo } from 'services/HomeServices'
import { columns, getData } from 'constants/pages/ticker-profile'

const CompanyInfo = () => {
  const [companyInfo, setCompanyInfo] = React.useState<any>()
  const tickerSymbol = useParams().ticker

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getCompanyInfo()
        setCompanyInfo(resp)
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [])
  const currentData = companyInfo?.find(
    (item: any) => item.symbol?.toLowerCase() == tickerSymbol,
  )
  const data = getData(currentData)
  return (
    <div>
      <Table columns={columns} dataSource={data} />
    </div>
  )
}

export default CompanyInfo
