import { useState, useEffect } from 'react'
import { TooltipProps } from 'recharts'

import {
  TooltipWrapper,
  ScrollCardContainer,
  CardContainer,
  StoryDescription,
  NepseContainer,
  NepseHeading,
  RecapContainer,
  SubHeading,
  SubHeadingValue,
} from './styles'
import { TimelineData } from '../line-chart/types'
import formatCompactNumber from 'utils/numberFormatter'
import { initialTooltipData } from '../line-chart'
import { useWindowDimensions } from 'hooks'

export type ToolTip = TooltipProps<number, string> & {
  customData: TimelineData
  hideTooltip: boolean
  payload?: any
  timelineData: TimelineData[]
}

const CustomTooltip = ({ payload, timelineData, customData }: ToolTip) => {
  const [currentTimelineData, setCurrentData] =
    useState<TimelineData>(initialTooltipData)

  useEffect(() => {
    let nullCheckedPayload = payload || []
    let selectedYearData = timelineData.find(
      (data: TimelineData) =>
        data.year === nullCheckedPayload[0]?.payload?.Year,
    )
    setCurrentData(selectedYearData || initialTooltipData)
  }, [payload])
  let mapData = currentTimelineData
  const { width } = useWindowDimensions()

  if (customData.year > 0 && width < 769) mapData = customData

  return (
    <TooltipWrapper>
      <ScrollCardContainer>
        {mapData.event.length > 0 ? (
          mapData.event.map((desc, i) => {
            return (
              <CardContainer reverseFlex={i % 2 == 0 ? true : false}>
                <div className={`w-72 mb-3 ${i % 2 == 0 ? 'ml-3' : 'mr-3'}`}>
                  {/* <StoryHeading> <div dangerouslySetInnerHTML={{ __html: desc.body }} /> </StoryHeading> */}
                  <StoryDescription>
                    <div dangerouslySetInnerHTML={{ __html: desc.body }} />
                  </StoryDescription>
                </div>
                <div className="flex">
                  <img
                    src={desc.image}
                    alt={'img'}
                    className="w-48 w-31 h-[120px] object-cover"
                  />
                </div>
              </CardContainer>
            )
          })
        ) : (
          <></>
        )}
      </ScrollCardContainer>
      <NepseContainer absolutePositioning={mapData.event.length > 0}>
        <div>
          <NepseHeading> NEPSE </NepseHeading>
          <div className="flex">
            <Recap
              subHeading="High"
              subValue={formatCompactNumber(mapData.high)}
              rise={true}
              firstChild
            />
            <Recap
              subHeading="Low"
              subValue={formatCompactNumber(mapData.low)}
              rise={false}
              fullBorder
            />
            <Recap
              subHeading="Open"
              subValue={formatCompactNumber(mapData.open)}
            />
            <Recap
              subHeading="Close"
              subValue={formatCompactNumber(mapData.close)}
            />
          </div>
        </div>
        <div className="ml-10 tablet:ml-3">
          <NepseHeading> ABC </NepseHeading>
          <div className="flex">
            {/* <Recap
              subHeading="Capital Growth"
              subValue={
                formatCompactNumber(mapData?.abc_capital_growth) || 'N/A'
              }
            /> */}
            <Recap
              subHeading="Total Turnover"
              subValue={
                formatCompactNumber(mapData?.abc_total_turnover) || 'N/A'
              }
            />
          </div>
        </div>
      </NepseContainer>
    </TooltipWrapper>
  )
}

const Recap = ({
  subHeading,
  subValue,
  imgSrc,
  rise = undefined,
  fullBorder,
  firstChild,
}: {
  subHeading: string
  subValue: string | number | undefined
  imgSrc?: string
  rise?: boolean | undefined
  fullBorder?: boolean
  firstChild?: boolean
}) => {
  return (
    <RecapContainer fullBorder={fullBorder} firstChild={firstChild}>
      <SubHeading> {subHeading} </SubHeading>
      <SubHeadingValue> {subValue} </SubHeadingValue>
      {/* {rise !== undefined && (
        <img
          src={
            rise ? '/images/upwards_vector.svg' : '/images/downwards_vector.svg'
          }
          height="8px"
          width="13.33px"
          alt="fall"
          className="inline ml-0.5"
        />
      )} */}
    </RecapContainer>
  )
}

export default CustomTooltip
