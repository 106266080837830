import { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useParams } from 'react-router'

import envConfig from 'services/config'
import AuthorInfo from 'components/author-info'
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  ViberShareButton,
  ViberIcon,
  PinterestShareButton,
  PinterestIcon,
  HatenaShareButton,
  HatenaIcon,
  PocketShareButton,
  PocketIcon,
} from 'react-share'

export type BlogHeadingProps = {
  author: string
  createdAt: string
}

const BlogHeading= ({ author, createdAt }: BlogHeadingProps) => {
  const [displayExtraSocials, setDisplayExtraSocials] = useState<boolean>(false)
  let { id } = useParams()
  let shareUrl
  if (id) shareUrl ||= `${envConfig.BASE_URL}/blog-detail/${id}`
  else shareUrl = `${envConfig.BASE_URL}/blogs`

  return (
    <>
      <div className="flex justify-between 767:block">
        <div className="flex items-center 767:block">
          <AuthorInfo
            authorImg="/images/blogs/Author_dp.png"
            authorName={author}
          />
          <div className="767:absolute 767:top-[55px] 767:right-[60px]">
            <BlogDetailSpan>{moment(createdAt).format('MMMM DD, YYYY')}</BlogDetailSpan>
            <BlogDetailSpan>9 min read</BlogDetailSpan>
          </div>
        </div>
        <ShareContainer>
          <LinkedinShareButton url={shareUrl}>
            <img
              className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
              src="/images/blogs/LinkedIn.svg"
              alt="Share to LinkedIn"
            />
          </LinkedinShareButton>
          <FacebookShareButton url={shareUrl}>
            <img
              className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
              src="/images/blogs/Facebook.svg"
              alt="Share to Facebook"
            />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl}>
            <img
              className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
              src="/images/blogs/Twitter.svg"
              alt="Share to Twitter"
            />
          </TwitterShareButton>
          <button
            onClick={() => {
              navigator.clipboard.writeText('www.houston.com')
            }}>
            <img
              className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
              src="/images/blogs/Copy.svg"
              alt="Copy to Clipboard"
            />
          </button>
          <button onClick={() => setDisplayExtraSocials(!displayExtraSocials)}>
            <img
              className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
              src="/images/blogs/Share.svg"
              alt="Copy to Share"
            />
          </button>
        </ShareContainer>
      </div>
      {displayExtraSocials && (
        <ExtraShareContainer>
          <RedditShareButton url={shareUrl}>
            <RedditIcon size={24} />
          </RedditShareButton>
          <TumblrShareButton url={shareUrl}>
            <TumblrIcon size={24} />
          </TumblrShareButton>
          <ViberShareButton url={shareUrl}>
            <ViberIcon size={24} />
          </ViberShareButton>
          <TelegramShareButton url={shareUrl}>
            <TelegramIcon size={24} />
          </TelegramShareButton>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={24} />
          </WhatsappShareButton>
          <HatenaShareButton url={shareUrl}>
            <HatenaIcon size={24} />
          </HatenaShareButton>
          <PinterestShareButton url={shareUrl} media={''}>
            <PinterestIcon size={24} />
          </PinterestShareButton>
          <PocketShareButton url={shareUrl}>
            <PocketIcon size={24} />
          </PocketShareButton>
        </ExtraShareContainer>
      )}
    </>
  )
}

export const BlogDetailSpan = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 24px;
`

export const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 18px;
    &:hover {
    }
    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 18px;
    }
  }
  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 10px;
  }
`

export const ExtraShareContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  @media (max-width: 767px) {
    display: block;
  }
  button {
    margin-left: 18px;
    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 18px;
    }
  }
`

export default BlogHeading
