import Wrapper from './Wrapper'

const FinanceIcon = () => {
  return (
    <Wrapper>
      <g>
        <ellipse
          cx="504"
          cy="360"
          rx="264"
          ry="88"
          fill="none"
          stroke="#ffffff"
          strokeWidth={16}
          strokeMiterlimit={10}
        />
        <path d="M768 512c0-48.6-118.2 16-264 16-34.33 0-222.47 3.63-264-88-14.12-31.15-7.52-64.91 0-88M768 512c0 48.6-118.2 104-264 104s-264-72.6-264-24" />
        <path d="M768 672c0-48.6-118.2 24-264 24s-264-55.4-264-104" />
        <path d="M768 672c0 48.6-118.2 104-264 104s-264-39.4-264-88M567.98 400c40.82-15.94 50.64-45.21 28.39-64-19.51-16.47-63.12-24.4-85.18-16-37.09 14.13 8.79 65.97-28.39 80-22.79 8.6-67.35-.56-85.18-16-23.89-20.69-6.77-58.71 56.79-80M336 368h320M336 336h320" />
      </g>
    </Wrapper>
  )
}

export default FinanceIcon
