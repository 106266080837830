import ColoredHeading from 'ui/Text/ColorHeading'

const FindOurOffice = () => {
  return (
    <div className="py-10 md:py-20 px-5 500:px-10 md:px-15">
      <ColoredHeading label="How to Get to Us" />
      <div className="font-secondary font-medium text-[32px] leading-[48px] mt-4 mb-[32px]">
        Find our offices
      </div>
      <div className="grid 1100:grid-cols-2 gap-12">
        <div>
          <h4 className="font-medium text-lg">Head Office</h4>
          <iframe
            className="w-full h-[250px] sm:h-[443px] 2xl:h-[600px] 3xl:h-[800px]"
            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Gha 2/86, Indra Chowk, Kathmandu 44600&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          />
        </div>
        <div>
          <h4 className="font-medium text-lg">Branch Office</h4>
          <iframe
            className="w-full h-[250px] sm:h-[443px] 2xl:h-[600px] 3xl:h-[800px]"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.700833747098!2d87.28425627479682!3d26.817652864170437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ef419dfdd6939f%3A0x3d5c84b29ac4bced!2sABC%20Securities!5e0!3m2!1sen!2snp!4v1700642534711!5m2!1sen!2snp"
    

          />
        </div>
      </div>
    </div>
  )
}

export default FindOurOffice
