import React from 'react'
import HtmlParser from 'react-html-parser'
import { Collapse } from 'antd'

import Accordion from 'components/accordion'
import { JobsType } from 'pages/Careers/_types'
import { getJobs } from 'services/CareersService'

let { Panel } = Collapse

const JobAccordion = () => {
  const [jobs, setJobs] = React.useState<JobsType>([])

  React.useEffect(() => {
    const fetchData = async () => {
      const resp: any = await getJobs()
      setJobs(resp.results)
    }

    fetchData()
  }, [getJobs])

  return (
    <Accordion>
      {jobs.map(({ title, body }, index) => (
        <Panel
          key={`title${index}`}
          header={title}
          className="font-medium text-base">
          <React.Fragment>{HtmlParser(body)}</React.Fragment>
        </Panel>
      ))}
    </Accordion>
  )
}

export default JobAccordion
