import { Collapse } from 'antd'
import { AccordionProps } from './_types'

let { Panel: AntdPanel } = Collapse

// FAQ data format example
const test = [
  {
    category: 'Account',
    sub_category: [
      {
        name: 'getting_started',
        question: 'how can we proceed?',
        answer: 'it is easy follow basic steps',
      },
      {
        name: 'online',
        question: 'how can we open online tms?',
        answer: 'it is easy follow basic steps at first',
      },
    ],
  },
  {
    category: 'Trading and market',
    sub_category: [
      {
        name: 'coroprate actions',
        question: 'how can we proceed?',
        answer: 'it is easy follow basic steps',
      },
      {
        name: 'other platforms',
        question: 'how can we open online tms?',
        answer: 'it is easy follow basic steps at first',
      },
    ],
  },
]

const Accordion = ({ children }: AccordionProps) => {
  return (
    <Collapse
      accordion
      bordered={false}
      ghost
      destroyInactivePanel
      className={`faq-collapse font-secondary`}
      expandIcon={({ isActive }) =>
        isActive ? (
          <img
            src="/images/minus.svg"
            alt="Answer Open"
            className="w-[26px] h-1 mr-2"
          />
        ) : (
          <img
            src="/images/plus.svg"
            alt="Answer Closed"
            className="md:w-[23px] h-[21px] mr-2 rotate-270"
          />
        )
      }
      expandIconPosition="end">
      {children}
    </Collapse>
  )
}

const Panel = ({ header, panel }: any) => (
  <AntdPanel header={header} key={header} className="font-medium text-base">
    {panel}
  </AntdPanel>
)

Accordion.Panel = Panel
export default Accordion
