import { Link } from 'react-router-dom'

import { LineChart } from 'components/charts'
import { useWindowDimensions } from 'hooks'

import {
  CompanyIndexType,
  // IndexBlockProps
} from './_type'

const graphData = [
  {
    time: 'Page A',
    stock_index: 2400,
  },
  {
    time: 'Page B',
    stock_index: -1398,
  },
  {
    time: 'Page C',
    stock_index: 9800,
  },
  {
    time: 'Page D',
    stock_index: 3908,
  },
  {
    time: 'Page E',
    stock_index: -4800,
  },
  {
    time: 'Page F',
    stock_index: 3800,
  },
  {
    time: 'Page G',
    stock_index: -4300,
  },
]

const IndexBlock = ({
  // name,
  // lastTradedPrice,
  // logo,
  // change,
  companyInfo,
}: {
  companyInfo: CompanyIndexType
}) => {
  const { width } = useWindowDimensions()
  const negative = Number(companyInfo?.point_change) < 0
  if(negative) companyInfo.percentage_change = -companyInfo.percentage_change
  const tickerSlug = companyInfo?.ticker?.toLowerCase()
  return (
    <Link
      className="bg-white rounded-[12px] p-[10px] cursor-pointer"
      to={`/ticker-profile/${tickerSlug}`}
      state={companyInfo}>
      <span className="flex items-center font-primary font-medium text-base text-black 767:text-[10px] 767:leading-4">
        {companyInfo.ticker}
        {/* <img src={logo} alt="NIC Asia Logo" className="ml-0.5 w-12 h-8" /> */}
      </span>
      <div className="flex justify-between align-start mt-1">
        <div className="flex flex-col">
          <span className="text-xs font-medium font-primary text-black 767:text-[8px] 767:leading-[8px]">
            Last Traded Price
          </span>
          <span
            className={`font-semibold text-base leading-[24px] font-primary 767:text-[10px] ${
              negative ? 'text-[#FF452C]' : 'text-[#5CAD00]'
            }`}>
            {companyInfo.latest_price || 'N/A'}
          </span>
          <span
            className={`font-medium text-sm font-primary 767:text-[8px] ${
              negative ? 'text-[#FF452C]' : 'text-[#5CAD00]'
            }`}>
            {`${companyInfo?.percentage_change}%`}
          </span>
        </div>
        <LineChart
          data={companyInfo?.chartData?.map((item) => ({
            stock_index: item?.value,
            time: item?.timestamp,
          }))}
          negative={negative}
          width={width < 600 ? 78 : 118}
        />
      </div>
    </Link>
  )
}

export default IndexBlock
