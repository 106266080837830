type SectionHeadingProps = {
  label: string
  extraClass?: string
}

const SectionHeading = ({ label, extraClass = '' }: SectionHeadingProps) => {
  return (
    <h2
      className={`capitalize font-medium font-secondary text-[32px] leading-[32px] md:leading-[48px] my-4 767:text-[24px] ${extraClass}`}>
      {label}
    </h2>
  )
}

export default SectionHeading
