import React from 'react'
import HtmlParser from 'react-html-parser'

import ColoredHeading from 'ui/Text/ColorHeading'
import {
  LeftGradient,
  RightGradient,
  BottomGradient,
} from 'pages/StepOne/features/why-choose-us'
import { getWhyAbc } from 'services/ServicesService'
import { ServiceListTypes } from '../_types'

const WhyAbc = () => {
  const [servicesList, setServicesList] = React.useState<ServiceListTypes[]>([])

  React.useEffect(() => {
    const fetchData = async () => {
      const resp: any = await getWhyAbc()
      setServicesList(resp.results)
    }
    fetchData()
  }, [getWhyAbc])

  return (
    <div className="relative py-20 px-6 500:px-15 overflow-hidden">
      <LeftGradient
        style={{ background: '#C9BBFF', height: '40%', filter: 'blur(250px)' }}
      />
      <RightGradient
        style={{ background: '#FAFFBB', height: '40%', filter: 'blur(250px)' }}
      />
      <BottomGradient
        style={{
          background: '#FEB8B4',
          height: '50%',
          left: '10%',
          filter: 'blur(250px)',
        }}
      />
      <ColoredHeading label="Why ABC?" />
      <span className="font-secondary font-medium text-2xl 500:text-[32px] leading-[48px]">
        We provide following services without delays and with guaranteed
        security
      </span>
      <ol className="text-left list-decimal md:pl-10">
        {servicesList?.map(({ question, answer }) => (
          <React.Fragment key={question}>
            <li className="font-primary font-bold text-lg 500:text-2xl my-6">
              {question}
            </li>
            <span className="font-primary  text-lg 500:text-2xl">
              {HtmlParser(answer)}
            </span>
          </React.Fragment>
        ))}
      </ol>
    </div>
  )
}

export default WhyAbc
