import React from 'react'
import { useParams } from 'react-router-dom'
import { Table } from 'antd'

import { getNews } from 'services/CompanyProfileService'
import NewsBlock from 'components/news-block'

const columns = [
  {
    title: 'Latest News',
    dataIndex: 'text',
    key: 'title',
    render: (text: string, record: any) => (
      <NewsBlock href={record.url} title={text} date={record.publishedDate} />
    ),
  },
]

const News = () => {
  const [news, setNews] = React.useState<any>()
  const tickerSymbol = useParams().ticker
  const fetchCompanyNews = async () => {
    const resp = await getNews(tickerSymbol)
    setNews(resp.news_list)
  }

  React.useEffect(() => {
    fetchCompanyNews()
  }, [])

  return (
    <div>
      <Table columns={columns} dataSource={news} />
    </div>
  )
}

export default News
