import axios from 'axios'
import envConfig from './config'
console.log('config', envConfig)
const baseURL = `${envConfig.API_URL}`
const baseURLNepse = `${envConfig.BASE_URL_NEPSE}`

const axiosInstance = axios.create({
  baseURL,
})

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const accessToken = localstorage.getItem('accessToken')
//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`
//     }
//     return config
//   },
//   (err) => {
//     toast.error('Request failed. Please check your internet connection')
//     return Promise.reject(err)
//   },
// )

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       toast.error(error.response.data.message)
//     } else if (error.response.status === 403) {
//       localstorage.removeItem('accessToken')
//       localstorage.removeItem('user')
//       history.push('/login')
//     } else {
//       toast.error(error.response.data.message)
//     }
//     return Promise.reject(error)
//   },
// )

export default axiosInstance

export const axiosInstanceNepse = axios.create({
  baseURL: baseURLNepse,
})
