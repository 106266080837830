import {
  LeftGradient,
  MidGradient,
  RightGradient,
} from 'components/header'
import PageHeader from 'components/page-header'

const AboutOurCompany = () => {
  return (
    <div className="pb-10 500:pb-15">
      <PageHeader
        title="About our Company"
        heading="Our Story"
        extraClass="lg:w-5/12">
        <p className="font-primary font-medium text-base 500:text-xl">
        ABC Securities Private Limited started its operations on 10th Shrawan, 2054 B.S. (25th July 1997) and it was among the first 25 firms that were given Brokerage License from Nepal Stock Exchange Ltd.
        </p>
      </PageHeader>
      {/* <div className="relative -mt-10 md:-mt-20"> */}
      <div className="relative ">
        <img
          src="/images/about/ABCTeam.png"
          alt="people discussing about ABC securitues"
          className="rounded-3xl md:rounded-[45px] mx-auto mt-10 w-[72%] h-full object-contain bg-white"
        />
        <img
          src="/images/about/line.svg"
          alt="thread vector"
          className="absolute top-0 mx-auto w-full"
        />
      </div>
      <LeftGradient style={{ height: '50%', filter: 'blur(260px)', top: 0 }} />
      <MidGradient style={{ height: '40%', filter: 'blur(260px)', top: 0 }} />
      <RightGradient style={{ height: '40%', filter: 'blur(260px)', top: 0 }} />
    </div>
  )
}

export default AboutOurCompany
