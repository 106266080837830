function formatCompactNumber(num: number) {
  // if (num < 1000) {
  //   return num
  // } else if (num >= 1000 && num < 1_000_000) {
  //   return (num / 1000).toFixed(1) + 'K'
  // } else if (num >= 1_000_000 && num < 1_000_000_000) {
  //   return (num / 1_000_000).toFixed(1) + 'M'
  // } else if (num >= 1_000_000_000 && num < 1_000_000_000_000) {
  //   return (num / 1_000_000_000).toFixed(1) + 'B'
  // } else if (num >= 1_000_000_000_000 && num < 1_000_000_000_000_000) {
  //   return (num / 1_000_000_000_000).toFixed(1) + 'T'
  // }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}


function formatNumberWithCommas(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default formatCompactNumber
