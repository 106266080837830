import Button from 'ui/Button'
import { BannerContentProps } from '../_types'

const BannerContent = ({
  title,
  subTitle,
  buttonLabel,
  buttonUrl,
}: BannerContentProps) => {
  return (
    <div className="flex flex-col items-center justify-center md:w-132.75 mx-auto mb-15">
      <span className="text-[40px] 500:text-5xl sm:text-6xl md:text-7xl font-medium font-primary">
        {title}
      </span>
      <span className="font-semibold text-xl 500:text-2xl sm:text-3xl md:text-[32px] text-center my-6 px-2 pb-6">
        {subTitle}
      </span>
      <Button
        type="primary"
        label={buttonLabel}
        extraClass="uppercase h-12 text-lg w-65 font-secondary"
        onClick={() => {
          window.open(buttonUrl, '_blank')
        }}
      />
    </div>
  )
}

export default BannerContent
