import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import moment from 'moment'

import BlogCard from 'components/blog-card'
import { getBlogCategoryList } from 'services/BlogService'
import { BlogCardProps } from 'components/blog-card/_types'
import { initialBlogList } from 'pages/Blogs'

const RelatedPosts = ({ category = 'Recent' }: { category: string }) => {
  const [blogList, setBlogList] = useState<any>(initialBlogList)

  const { id } = useParams()
  useEffect(() => {
    let resultsCopy: BlogCardProps[] = []
    const fetchData = async () => {
      const data = await getBlogCategoryList(category)
      // Get 4 related posts besides itself
      if (data && data.results && data.results.length > 0) {
        data.results.map((blog) => {
          if (blog.id != id && resultsCopy.length < 4) resultsCopy.push(blog)
        })
      }
      setBlogList(resultsCopy)
      return data
    }
    const result = fetchData()
  }, [])
  return (
    <div className="">
      <RelatedBlogHeader> Related Blogs </RelatedBlogHeader>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 place-items-center gap-8 py-15">
        {blogList && blogList.length > 0 ? (
          blogList.map((blog: BlogCardProps, index: number) => (
            <BlogCard
              key={index}
              thumbnailSrc={blog.thumbnailSrc}
              title={blog.title}
              description={blog.description}
              category={blog.category}
              date={moment(blog.date).format('MMMM DD, YYYY')}
              author={blog.author}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const RelatedBlogHeader = styled.h3`
  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  text-align: left;
  @media (max-width: 769px) {
    font-size: 24px;
    line-height: inherit;
  }
`

export default RelatedPosts
