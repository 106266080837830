import ReactHtmlParser from 'react-html-parser'

type SubHeadingTypes = {
  label: string
  extraClass?: string
}

const SubHeading = ({ label = '', extraClass = '' }: SubHeadingTypes) => {
  return (
    <h4
      className={`text-base text-subHead 500:text-xl 500:leading-[32px] font-primary ${extraClass}`}>
      {ReactHtmlParser(label)}
    </h4>
  )
}

export default SubHeading
