import { Link } from 'react-router-dom'

import { NewsBlockProps } from 'pages/TickerProfile/_types'

const NewsBlock = ({ href, date, title }: NewsBlockProps) => {
  return (
    <Link to={href} className="flex items-center justify-between">
      <span>{title}</span>
      <span className="text-[10px] text-subHead min-w-[55px] ml-2">{date}</span>
    </Link>
  )
}

export default NewsBlock
