import React, { useCallback } from 'react'
import GraphBlock from 'components/graph-block'
import {
  graphBlock,
  nepseIndexBlock,
  overlayGraphBlock,
  sliderContainer,
} from './styles'
import { TickerType } from './_types'
import { getIndicesList } from 'services/indicesService'
import { REFETCH_INTERVAL } from 'config/nepseData'

const Slider: React.FC = () => {
  const [animateSlider, setAnimate] = React.useState<boolean>(false)
  const [tickerList, setTickerList] = React.useState<TickerType[]>([])

  const getData = useCallback(
    () =>
      getIndicesList()
        .then((data) => {
          setTickerList(data)
        })
        .catch((err) => {
          console.log(err)
        }),
    [],
  )

  React.useEffect(() => {
    getData()
    const handler = setInterval(getData, REFETCH_INTERVAL)

    return () => clearInterval(handler)
  }, [])

  return (
    <div
      className={sliderContainer}
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}>
      <GraphBlock
        title={'Nepse'}
        slug="nepse"
        extraClass={nepseIndexBlock}
        nepse
      />

      <div
        className={`${graphBlock} ${
          !animateSlider ? 'play-slider-animation' : 'pause-slider-animation'
        }`}>
        {tickerList?.length > 0 &&
          tickerList
            // ?.slice?.(1, tickerList?.length)
            ?.filter((tic) => tic.indices_name !== 'Nepse')
            ?.map?.((sector) => (
              <GraphBlock
                key={sector?.indices_name}
                title={sector?.indices_name}
                slug={sector?.slug}
              />
            ))}
      </div>
      <div
        className={`${overlayGraphBlock} ${
          !animateSlider ? 'play-slider-animation' : 'pause-slider-animation'
        }`}>
        {tickerList
          // ?.slice(1, tickerList?.length)
          ?.filter((tic) => tic.indices_name !== 'Nepse')
          ?.map((sector) => (
            <GraphBlock
              key={sector?.indices_name}
              title={sector?.indices_name}
              slug={sector?.slug}
            />
          ))}
      </div>
    </div>
  )
}

export default Slider
