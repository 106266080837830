import React, { useCallback } from 'react'
import { useParams } from 'react-router'

// import { getRecapData } from 'constants/pages/ticker-profile'
import { getShareDetail } from 'services/CompanyProfileService'
import LTSBlock from './features/lts-block'
import TickerTabs from './features/ticker-tabs'
import RecapBlock from './features/recap-block'
import { CompanyDetailType } from 'components/index-block/_type'
import { getTickerDetail } from 'services/tickerService'
import { REFETCH_INTERVAL } from 'config/nepseData'

const TickerProfile = () => {
  const [shareDetails, setShareDetails] = React.useState<any>([])
  const [indexDetails, setIndexDetails] = React.useState<
    CompanyDetailType | undefined
  >()
  const tickerSymbol = useParams().ticker

  const fetchShareDetails = async () => {
    try {
      const resp = await getShareDetail(tickerSymbol)
      setShareDetails(resp)
    } catch (e) {
      console.log(e)
    }
  }

  const getData = useCallback(() => {
    tickerSymbol &&
      getTickerDetail(tickerSymbol)
        .then((data) => setIndexDetails(data))
        .catch((err) => console.log(err))
  }, [tickerSymbol])

  React.useEffect(() => {
    getData()
    const handler = setInterval(getData, REFETCH_INTERVAL)
    return () => clearInterval(handler)
  }, [tickerSymbol])

  React.useEffect(() => {
    window?.scrollTo(0, 0)
    // fetchShareDetails()
  }, [])

  // const recapData = getRecapData(shareDetails)
  const recapData = [
    {
      title: 'previous close',
      value: indexDetails?.open || 'N/A',
    },
    {
      title: 'open',
      value: indexDetails?.open || 'N/A',
    },
    {
      title: 'high',
      value: indexDetails?.high || 'N/A',
    },
    {
      title: 'low',
      value: indexDetails?.low || 'N/A',
    },
    {
      title: 'volume',
      value: indexDetails?.volume || 'N/A',
    },
  ]
  return (
    <div className="p-8 md:p-12.5">
      <div className="flex items-center mb-5">
        {/* <img
          src="/images/nic_asia_logo.svg"
          className="hidden sm:block md:mr-8"
        /> */}
        <h1 className="500:text-base text-xl sm:text-2xl md:text-3xl lg:text-5xl font-bold">
          {indexDetails
            ? `${indexDetails?.ticker_name} (${indexDetails?.ticker})`
            : '-'}
        </h1>
      </div>
      <div className="grid sm:grid-cols-12 gap-y-5 sm:gap-10 items-center mb-6.5">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-y-4 md:gap-2 lg:flex lg:justify-between text-left col-span-12 sm:col-span-9 border-2 border-solid border-black rounded-xl p-4 w-full">
          {recapData.map(({ title, value }) => (
            <RecapBlock key={title} title={title} value={value as string} />
          ))}
        </div>
        <LTSBlock
          percentChange={(indexDetails?.percentage_change || '') as string}
          // ltp={indexDetails?.ltp}
          ltp={indexDetails?.latest_price}
        />
      </div>
      <TickerTabs />
    </div>
  )
}

export default TickerProfile
