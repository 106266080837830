import { useState, useEffect } from 'react'

import { getUserDetail } from 'services/AboutService'

import ColoredHeading from 'ui/Text/ColorHeading'
import SectionHeading from 'ui/Text/SectionHeading'
import MissionCard from 'components/mission-card'
import { MissionType } from './_types'

const OurMission: React.FC = () => {
  const [missions, setMissions] = useState<null | MissionType[]>([])

  useEffect(() => {
    getUserDetail().then((missions) => {
      setMissions(missions)
    })
  }, [])

  return (
    <div className="py-10 md:py-20 px-7.5 md:px-15 text-center lg:text-left">
      <div className="flex flex-col items-center lg:flex-row lg:justify-between mb-8">
        <div className="flex flex-col mb-2 lg:mb-0 lg:w-1/3">
          <ColoredHeading label="ABC is About" />
          <SectionHeading label="Focusing on financial literacy. Always." />
        </div>
        <p className="lg:w-1/2">
          To achieve this ambitious goal we are invested into financial
          education and wealth management, first starting with simplifying and
          making Capital Market and its opportunities accessible to every Nepali
          wherever they may reside.
        </p>
      </div>
      <div className="our-mission-grid grid grid-flow-col auto-cols-max gap-5 justify-between overflow-x-auto pb-2">
        {missions ? (
          missions.map((mission) => (
            <MissionCard
              key={mission.name}
              data={mission}
              missions={missions}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default OurMission
