const TabLabel = ({ label = 'Company Info' }) => {
  return (
    <span className="uppercase font-roboto flex items-center justify-between w-full px-2">
      {label}{' '}
      <img
        src="/images/chevron_filled.svg"
        alt="chevron"
        className="ml-2 rotate-90 lg:rotate-0"
      />
    </span>
  )
}

export default TabLabel
