import { Modal as AntdModal } from 'antd'

import Header from 'components/header'
import { ModalProps } from './_types'

const Modal = ({
  showModal,
  setShowModal,
  handleCloseModal,
  name,
  title,
  children,
}: ModalProps) => {
  return (
    <AntdModal
      title=""
      centered
      open={showModal}
      closable={false}
      width={'100%'}
      footer={null}
      mask={false}
      wrapClassName={`bg-white z-auto`}
      className={``}
      destroyOnClose
      onCancel={() => setShowModal(false)}>
      <>
        <Header modalHeader />
        <div className="flex items-center justify-between mb-6 pt-15 px-6 500:px-10 lg:pt-10 md:px-20">
          <h1 className="font-primary font-medium text-2xl leading-8 ">
            {title}
          </h1>
          <button
            className="flex font-bold text-5xl w-10 h-9"
            onClick={handleCloseModal}>
            <img src="/images/plus.svg" className="rotate-315 w-10 h-9" />
          </button>
        </div>
        {/* The following section holds the actual modal contents based on the requirements */}
        <div className="px-6 500:px-10 md:px-20 bg-white">{children}</div>
      </>
    </AntdModal>
  )
}

export default Modal
