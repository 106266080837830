const ColoredHeading = ({ label = '', extraClass = '' }) => {
  return (
    <h3
      className={`uppercase font-bold text-sm text-[#FF952C] font-inter 767:text-[14px] ${extraClass}`}>
      {label}
    </h3>
  )
}

export default ColoredHeading
