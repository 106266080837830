import { FieldProps, ElementProps } from './_types'

const Field = ({
  label,
  id,
  children,
  variation,
  extraClass,
  error,
}: FieldProps) => {
  return (
    <>
      <span className={`flex flex-col items-start mt-2.5 ${extraClass}`}>
        <label
          htmlFor={id}
          className={`text-sm leading-[22px] mb-1.5 ${
            variation === 'cv'
              ? 'font-primary font-medium text-base'
              : 'font-roboto'
          }`}>
          {label}
        </label>
        {children}
        {error && error.length > 0 && (
          <div className="text-red-600 text-[12px]">{error}</div>
        )}
      </span>
    </>
  )
}

const Input = ({
  placeholder,
  name,
  type,
  variation,
  value,
  onChange,
  extraClass,
}: ElementProps) => {
  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className={`border border-solid py-3.5 px-3 w-full ${
          variation === 'cv'
            ? 'border-2 rounded-xl border-black'
            : 'font-roboto rounded border-[#D9D9D9]'
        }  ${extraClass}`}
      />
    </>
  )
}

const TextArea = ({
  placeholder,
  name,
  variation,
  value,
  onChange,
}: ElementProps) => {
  return (
    <>
      <textarea
        placeholder={placeholder}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className={`border border-solid py-3.5 px-3 w-full ${
          variation === 'cv'
            ? 'border-2 rounded-xl border-black'
            : 'font-roboto rounded border-[#D9D9D9]'
        }`}
      />
    </>
  )
}

Field.Input = Input
Field.TextArea = TextArea
export default Field
