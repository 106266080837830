import React from 'react'
import ProfileModal from 'components/modal/profile-modal'
import { MissionCardProps } from './_types'
import { MissionType } from 'pages/AboutUs/features/our-mission/_types'
import MissionCardLoader from './skeleton'

const MissionCard = ({ data, missions }: MissionCardProps) => {
  const [showModal, setShowModal] = React.useState(false)
  const [modalData, setModalData] = React.useState<MissionType>({
    name: '',
    position: '',
    image: '',
    description: '',
  })

  const handleModal = () => {
    setShowModal(true)
    setModalData(data as any) // TODO: will need to update this type
  }

  return (
    <>
      {missions && missions.length > 0 ? (
        <div
          className="border-4 border-solid border-black p-3.75 max-w-[356px] rounded-xl cursor-pointer"
          onClick={handleModal}>
          <img
            src={data.image}
            alt={data.name}
            className="rounded-xl h-80 w-full object-cover"
          />
          <div className="flex flex-col items-center mt-3.75">
            <span className="font-secondary font-bold text-base mb-2">
              {data.name}
            </span>
            <span className="text-center font-medium font-secondary text-xs leading-[18px]">
              {data.description}
            </span>
          </div>
        </div>
      ) : (
        <MissionCardLoader />
      )}

      <ProfileModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalData={modalData}
        missions={missions}
      />
    </>
  )
}

export default MissionCard
