import Wrapper from './Wrapper'

const HotelsIcon = () => {
  return (
    <Wrapper>
      <path d="M960 928H840c-13.25 0-24-10.75-24-24v-64c0-30.8-25.2-56-56-56H200c-30.8 0-56 25.2-56 56v64c0 13.25-10.75 24-24 24h-16c-13.25 0-24-10.75-24-24V424c0-13.25 10.75-24 24-24h16c13.25 0 24 10.75 24 24v288c0 13.25 10.75 24 24 24h672c13.25 0 24 10.75 24 24v96c0 13.25 10.75 24 24 24s24-10.75 24-24V651.9c0-7.64-3.63-14.83-9.8-19.35C849.63 593.99 653.31 467.95 464 528c-25.5 8.09-54.64 20.67-85.24 40.69-6.74 4.41-10.76 11.96-10.76 20.02V680c0 13.25-10.75 24-24 24h-69.58c-1.61 0-3.22-.15-4.79-.48C208.78 690.69 170.52 639.43 176 592c4.2-36.35 35.09-79 80-80 37.31-.83 72.38 27.1 80 64 9.35 45.27-25.13 92.08-64 96-21.79 2.2-39.75-9.61-48-16" />
    </Wrapper>
  )
}

export default HotelsIcon
