import React from 'react'
import { notification } from 'antd'

import { postCv } from 'services/CareersService'
import Button from 'ui/Button'
import Field from 'ui/InputField'
import { ClipIcon, DeleteIcon } from 'ui/Icons'
import { ModalProps } from '../_types'
import Modal from '..'

const initialFormState = {
  name: '',
  email: '',
  phone: '',
  message: '',
  cv: '',
}

type NotificationType = 'success' | 'info' | 'warning' | 'error'

const CvModal = ({ showModal, setShowModal }: ModalProps) => {
  const fileInput = React.createRef<HTMLInputElement>()
  const [formData, setFormData] = React.useState(initialFormState)
  const [errorData, setErrorData] = React.useState(initialFormState)
  const [selectedFile, setSelectedFile] = React.useState<
    File | null | undefined
  >(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()

  const openNotificationWithIcon = (
    type: NotificationType,
    description: string,
  ) => {
    api[type]({
      message: type.toUpperCase(),
      description: description,
    })
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    setErrorData({ ...errorData, [e.target.name]: false })
  }

  const handleFileChange = () => {
    setSelectedFile(fileInput?.current?.files && fileInput?.current?.files[0])
  }

  const handleCloseModal = () => {
    setLoading(false)
    setShowModal(false)
    setFormData(initialFormState)
    setSelectedFile(null)
    setErrorData(initialFormState)
  }

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    const errorCopy = {
      name: '',
      email: '',
      phone: '',
      message: '',
      cv: '',
    }

    try {
      setLoading(true)
      const resp = await postCv({ ...formData, cv: selectedFile })

      if (resp.status >= 200 && resp.status < 300) {
        openNotificationWithIcon(
          'success',
          'Your CV has been successfully sent!',
        )
        handleCloseModal()
      }
    } catch (e: any) {
      if (e.response.status == 400) {
        const { data } = e.response
        if (data.email.length > 0) errorCopy.email = data.email[0]
        if (data.phone.length > 0) errorCopy.phone = data.phone[0]
        if (data.name.length > 0) errorCopy.name = data.name[0]
        if (data.cv.length > 0) errorCopy.cv = data.cv[0]

        setErrorData(errorCopy)
        openNotificationWithIcon(
          'error',
          'An error occured! Please check your data and try again',
        )
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        handleCloseModal={handleCloseModal}
        title="Drop your CV">
        <form
          className="border-4 border-solid border-black h-full w-full rounded-[20px] p-5 500:p-12.5"
          onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-7.5 mb-5">
            <Field
              label="Name"
              type="text"
              id="name"
              variation="cv"
              error={errorData.name}
              extraClass="col-span-4">
              <Field.Input
                placeholder=""
                name="name"
                variation="cv"
                value={formData.name}
                onChange={handleChange}
              />
            </Field>
            <Field
              label="Email"
              type="email"
              id="email"
              variation="cv"
              error={errorData.email}
              extraClass="col-span-4">
              <Field.Input
                placeholder=""
                name="email"
                variation="cv"
                value={formData.email}
                onChange={handleChange}
              />
            </Field>
            <Field
              label="Phone"
              type="number"
              id="phone"
              variation="cv"
              error={errorData.phone}
              extraClass="col-span-4">
              <Field.Input
                placeholder=""
                name="phone"
                variation="cv"
                value={formData.phone}
                onChange={handleChange}
              />
            </Field>
          </div>
          <div className="grid grid-cols-1 gap-y-7.5 lg:grid-cols-12 gap-7.5">
            <Field
              label="Message"
              type="text"
              id="message"
              variation="cv"
              extraClass="shrink-0 col-span-10 lg:col-span-8">
              <Field.TextArea
                name="message"
                variation="cv"
                value={formData.message}
                onChange={handleChange}
              />
            </Field>
            {selectedFile ? (
              <div className="col-span-12 lg:col-span-4">
                <div className="flex items-center justify-between font-bold w-full">
                  <div className="flex items-center">
                    <ClipIcon className="shrink-0 w-4 h-4 mr-2" />
                    <span>{selectedFile?.name}</span>
                  </div>

                  <button onClick={() => setSelectedFile(null)}>
                    <DeleteIcon className="w-4 h-4 text-red-500" />
                  </button>
                </div>
                {errorData && errorData?.cv && (
                  <div className="text-red-600 text-[12px]">{errorData.cv}</div>
                )}
              </div>
            ) : (
              <div className="flex flex-col col-span-10 lg:col-span-4 flex justify-center">
                <label
                  htmlFor="cv"
                  className="flex items-center justify-center grow-1 text-lg font-medium w-full border-2 border-solid border-black px-5 py-2.5 rounded-lg cursor-pointer h-[114px]">
                  <img
                    src="/images/attachment_icon.svg"
                    alt="Attach file"
                    className="mr-4 xl:mr-8"
                  />
                  Attach your CV
                </label>
                {errorData && errorData?.cv && (
                  <div className="text-red-600 text-[12px]">{errorData.cv}</div>
                )}
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  id="cv"
                  name="cv"
                  ref={fileInput}
                  onChange={handleFileChange}
                  hidden
                />
              </div>
            )}
          </div>
          <Button
            type="primary"
            htmlType="submit"
            label={!loading ? 'Submit' : 'Submitting'}
            loading={loading ? true : false}
            extraClass="block mx-auto mt-10 500:mt-20 w-200 500:w-[286px] h-[52px] !font-primary text-xl"
          />
        </form>
      </Modal>
    </>
  )
}

export default CvModal
