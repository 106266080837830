import React from 'react'

import {
  Table,
  Tabs,
} from 'antd'
import {
  balanceSheetColumns,
  getBalanceSheetData,
} from 'constants/pages/ticker-profile'
import { useParams } from 'react-router-dom'
import {
  getBalanceSheet,
  getIncomeStatement,
  getKeyStats,
} from 'services/CompanyProfileService'

const FinancialsTable = ({ getData }: { getData: (tickerSymbol: string) => Promise<any[]> }) => {
    const [financials, setFinancials] = React.useState([] as any[])
    const data = getBalanceSheetData(financials)
    const tickerSymbol = useParams().ticker || '';

    const fetchData = async () => {
        try {
            const resp = await getData(tickerSymbol)
            const result = resp.reduce((acc: any, obj: any, index: number) => {
                const entries = Object.entries(obj);
                entries.forEach(([key, value]) => {
                    const formattedKey = key.replace(/([A-Z])/g, ' $1').trim();
                    if (index === 0) {
                        acc.push({ quarter: formattedKey, q1: value });
                    } else {
                        acc.find((item: any) => item.quarter === formattedKey)[`q${index + 1}`] = value;
                    }
                });
                return acc;
            }, []);
            const _result = [result[4], ...result.slice(9)];
            console.log(_result);
            setFinancials(_result)
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className='overflow-scroll'>
            <Table columns={balanceSheetColumns} dataSource={data} />
        </div>
    )
}


const Financials = () => {
    const tabs = [
        { label: 'Balance Sheet', key: '1', children: <FinancialsTable getData={getBalanceSheet} /> },
        { label: 'Key Stats', key: '2', children: <FinancialsTable getData={getKeyStats} /> },
        { label: 'Income Statement', key: '3', children: <FinancialsTable getData={getIncomeStatement} />},
    ]

    return (
        <div>
            <Tabs defaultActiveKey="1" >
                {tabs.map((tab) => (
                    <Tabs.TabPane tab={tab.label} key={tab.key}>
                        {tab.children}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    )

}

export default Financials
