import TriangleIcon from 'ui/Icons/TriangleIcon'

const LTSBlock = ({ percentChange = '', ltp = '' }) => {
  return (
    <div className="flex flex-col items-start md:items-center order-first sm:order-none col-span-3 col-start-1 sm:col-start-11 sm:justify-self-end">
      {/* Latest traded price here */}
      <h2 className="flex items-center text-2xl md:text-3xl lg:text-5xl font-bold">
        {ltp}
        <TriangleIcon
          className={`ml-2 ${
            Number(percentChange) < 0
              ? 'text-[#FF452C]'
              : 'text-[#5CAD00] rotate-180'
          } `}
        />
      </h2>
      <h4 className="text-lg md:text-xl font-bold">{percentChange}</h4>
    </div>
  )
}

export default LTSBlock
