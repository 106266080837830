import Button from 'ui/Button'
import { DropCvProps } from '../../_types'

const DropCv = ({ setShowModal, extraClass }: DropCvProps) => {
  return (
    <div className={`${extraClass}`}>
      <span className="font-medium">Didn’t find the job of your interest?</span>
      <Button
        type="ghost"
        label="Drop your CV"
        extraClass="flex items-center justify-center text-xl w-full px-2.5 py-5 mt-2"
        onClick={() => setShowModal(true)}
      />
    </div>
  )
}

export default DropCv
