import React from 'react'

import { Table } from 'antd'
import {
  dividendColumns,
  getDividendData,
} from 'constants/pages/ticker-profile'
import { DividendHistoryProps } from 'pages/TickerProfile/_types'
import { useParams } from 'react-router-dom'
import { getDividentHistory } from 'services/CompanyProfileService'

const DividendHistory = () => {
  const [dividendHistory, setDividendHistory] = React.useState<DividendHistoryProps>([])
  const data = getDividendData(dividendHistory)
  const sorted = data.sort((a, b) => b.year.split['/'] - a.year)
  const tickerSymbol = useParams().ticker
  
  const fetchData = async () => {
    try {
      const resp = await getDividentHistory(tickerSymbol)
      const sorted = resp.sort((a:any, b:any) => b.year.localeCompare(a.year))
      setDividendHistory(sorted)
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      <Table columns={dividendColumns} dataSource={data} />
    </div>
  )
}

export default DividendHistory
