import { DematCardProps } from '../_types'

const DematCard = ({
  title,
  companyName,
  cardKey,
  extraClass,
  onMouseOver,
  currentDataIndex,
}: DematCardProps) => {
  return (
    <div
      className={`flex flex-col w-full h-[130px] sm:h-[150px] 500:w-[200px] sm:w-65 px-2 py-3 sm:px-6 py-4 sm:py-8 rounded-[10px] shadow-card z-2 cursor-pointer ${
        cardKey === currentDataIndex ? 'bg-dematActive' : 'bg-white'
      } ${extraClass} `}
      onMouseEnter={() => onMouseOver(cardKey)}
      onClick={() => onMouseOver(cardKey)}>
      <span className="font-semibold font-secondary text-sm 500:text-base sm:text-xl">
        {title}
      </span>
      <span className="text-sm 500:text-base opacity-60 mt-3">
        {companyName}
      </span>
    </div>
  )
}

export default DematCard
