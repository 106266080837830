import {
  LineChart as RechartsLine,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import { LineChartProps } from 'components/charts/_types'

/* eslint-disable */
const LineChart = ({
  data,
  negative = false,
  nepse = false,
  dark = false,
  width = 118,
  height = 41.3,
  strokeWidth = 1,
  extraClass = '',
  origin = 0,
}: LineChartProps) => {
  return (
    <ResponsiveContainer
      width={width}
      height={height}
      className={`${extraClass}`}>
      <RechartsLine data={data} margin={{ top: 5, bottom: 5 }}>
        <XAxis dataKey="time" hide />
        <YAxis dataKey="stock_index" domain={['dataMin', 'dataMax']} hide />
        <Line
          type="monotone"
          dataKey="stock_index"
          dot={false}
          stroke={
            nepse
              ? '#ffffff'
              : negative
              ? '#FF452C'
              : dark
              ? '#000000'
              : '#5CAD00'
          }
          strokeWidth={strokeWidth}
        />
        <ReferenceLine
          y={origin}
          label=""
          stroke={
            nepse
              ? '#ffffff'
              : negative
              ? '#FF452C'
              : dark
              ? '#000000'
              : '#5CAD00'
          }
          strokeDasharray="3"
        />
      </RechartsLine>
    </ResponsiveContainer>
  )
}

export default LineChart
