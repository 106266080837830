import React from 'react'
import { useNavigate } from 'react-router'

import Button from 'ui/Button'
import { JobAccordion, DropCv } from 'pages/Careers/features'
import PageHeader from 'components/page-header'
import CvModal from 'components/modal/cv-modal'

const Careers = () => {
  const [showModal, setShowModal] = React.useState(false)
  const navigate = useNavigate()

  return (
    <>
      <div>
        <PageHeader heading="Careers at ABC" title="Come work with us" />
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-[28px] justify-items-start py-10 px-7.5 md:py-20 md:px-15 bg-white text-left">
          <div className="lg:col-span-3">
            <h4 className="font-secondary font-medium text-2xl mb-6">
              Why work with us
            </h4>
            <div className="grid 500:grid-cols-4 gap-8 lg:grid-cols-1 lg:gap-0">
              <div className="flex flex-col col-span-2">
                {/* <span className="font-medium text-base leading-[32px]">
                  Wellness Allowance
                </span> */}
                <span className="text-base leading-[24px]">
                Join ABC for a rewarding career with benefits, serving as a licensed brokerage in Nepal.
                </span>
              </div>

              <div className="flex flex-col col-span-2 lg:my-5">
                <span className="font-medium text-base leading-[32px]">
                  Company Benefits
                </span>
                <span className="text-base leading-[24px]">
                ABC offers competitive benefits including health coverage, retirement plans, and professional growth opportunities for its employees.
                </span>
                <Button
                  type="link"
                  label="Learn More"
                  icon={<img src="/images/arrow_right.png" className="ml-4" />}
                  extraClass="flex justify-end lg:justify-start items-center mt-[18px] text-lg w-fit"
                  onClick={() => {
                    navigate('/about')
                  }}
                />
              </div>
              <DropCv
                setShowModal={setShowModal}
                extraClass="hidden lg:block col-span-2"
              />
            </div>
          </div>
          <div className="lg:col-span-9 mb-8 lg:mb-3 w-full">
            <h4 className="font-secondary font-medium text-2xl mb-6">
              We are looking for
            </h4>
            <JobAccordion />
          </div>
          <DropCv
            setShowModal={setShowModal}
            extraClass="block lg:hidden mx-auto"
          />
        </div>
      </div>
      <CvModal showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}

export default Careers
