import React from 'react'

import Drawer from 'components/drawer'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Header = ({ modalHeader = false }: { modalHeader?: boolean }) => {
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const handleClose = () => {
    setOpenDrawer(false)
  }

  return (
    <>
      <div
        className={`${
          modalHeader && 'bg-white'
        } relative flex justify-between font-medium bg-error-400 px-7.5 md:px-12.5 py-5.5 overflow-hidden`}>
        <LeftGradient modalHeader={modalHeader} />
        <MidGradient modalHeader={modalHeader} />
        <RightGradient modalHeader={modalHeader} />
        <Link to="/" className="hidden 500:block z-2">
          <img src="/images/abc_logo.svg" alt="ABC securities logo" />
        </Link>
        <Link to="/" className="500:hidden z-2">
          <img
            src="/images/abc_xs_logo.svg"
            alt="ABC securities logo for extra-small screens z-2"
          />
        </Link>
        <div className="flex space-between items-center z-2">
          <button
            className="flex items-center text-lg font-semibold uppercase mr-8"
            onClick={() => {
              window.open('https://kyc.abc.com.np', '_blank')
            }}>
            <img
              src="/images/login_icon.svg"
              className="mr-3.5"
              height="32px"
              width="27px"
              alt="login"
            />
            <span className="hidden md:block leading-6 font-primary text-black">
              Login
            </span>
          </button>
          <button
            className="cursor-pointer"
            onClick={() => setOpenDrawer(true)}>
            <img
              src="/images/hamburger_icon.svg"
              height="27.51px"
              width="31px"
              alt="hamburger"
            />
          </button>
        </div>
      </div>
      <Drawer openDrawer={openDrawer} handleClose={handleClose} />
    </>
  )
}

export default Header

type Gradient = {
  modalHeader?: boolean
}

export const LeftGradient = styled.div<Gradient>`
  position: absolute;
  left: 0;
  width: 50%;
  background: #bbfff3;
  filter: blur(400px);
  height: 50%;
  z-index: -1;
  ${(props) => props.modalHeader && `filter: blur(100px); z-index: 1;`}
`
export const RightGradient = styled.div<Gradient>`
  position: absolute;
  right: 0;
  width: 50%;
  background: #bbdaff;
  filter: blur(400px);
  height: 50%;
  z-index: -1;
  ${(props) => props.modalHeader && `filter: blur(100px); z-index: 1;`}
`

export const MidGradient = styled.div<Gradient>`
  position: absolute;
  left: 25%;
  width: 50%;
  background: #e3e3e3;
  filter: blur(400px);
  height: 50%;
  z-index: -1;
  ${(props) => props.modalHeader && `filter: blur(100px); z-index: 1;`}
`
