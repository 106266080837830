import Wrapper from './Wrapper'

const MutualFundIcon = () => {
  return (
    <Wrapper>
      <g id="Layer_1" data-name="Layer 1">
        <path d="M584.7,314.18c-13.74-24.27-39-30.11-55.19-16.88-14.2,11.6-21,37.53-13.8,50.66,12.19,22.05,56.89-5.23,69,16.88,7.41,13.55-.48,40.05-13.8,50.66-17.84,14.21-50.63,4-69-33.77" />
        <line x1="557.11" y1="452.14" x2="557.11" y2="261.84" />
        <line x1="539.98" y1="452.14" x2="539.98" y2="261.84" />
        <path d="M52.45,828.61s45.36-99.8,99.79-86.19,106.63,39.69,113.43,42c10.19,3.41,36.89,6.28,65.92-6.4a56.54,56.54,0,0,0,31.53-35.69,47.36,47.36,0,0,0,.7-23.56l-.07-.31A45.52,45.52,0,0,0,346,691.24c-31.62-23-108.32-78.75-142.26-103.43a81.26,81.26,0,0,1-25.51-30.65L111.6,417.82a74.6,74.6,0,0,1-7-38.24l10.27-126.22s10.21-36.58,25.52,0c7.83,18.7,13.43,42.74,17,61.77a98.89,98.89,0,0,1-6.72,58.45l-7.77,17.57-.86,7.12a18.64,18.64,0,0,0,9.59,18.59h0a58.44,58.44,0,0,1,13.32,10l8.57,8.56,62.09,72.29-88.46-86.75s-30.62-65.5,28.92-40c42.33,18.14,86.82,68.54,109,96.15a53.65,53.65,0,0,0,36.6,19.78c31.52,3.1,89.88,14.58,130.84,56.73,58.69,60.39,56.13,102.92,57,121.63s0,104.62,0,104.62L362.33,777.3s10.21-19.57,49.33-15.31,113.12,8.5,113.12,8.5l57,2.55,132.69-1.7V700.75L854,601.85A192.12,192.12,0,0,0,917.13,526L962,453.12c12.41-26.74,10-62.94,7.89-92.36l-7.4-104.63a22.25,22.25,0,0,0-9.2-16.74c-6.43-4.5-15.58-5.51-24.46,12.27-4.85,9.69-7.94,22.15-9.89,34.83a222.76,222.76,0,0,0,5.22,90.9l3.27,12.3a23.76,23.76,0,0,1-4.49,21.05l-10.28,12.73L842,503.42S973,400.65,914,386.58a41.31,41.31,0,0,0-35.75,8.57c-23.37,19.24-72.89,61.49-83.84,82.75-6.93,13.45-37.68,22.8-67.84,28.89A171.79,171.79,0,0,0,619.5,576.66l-18.8,26.93A198.16,198.16,0,0,0,565.15,709.7l-.59,16.1a303.51,303.51,0,0,0,.72,34.91l2,25.94" />
        <circle cx="543.71" cy="356.99" r="161.6" />
      </g>
    </Wrapper>
  )
}

export default MutualFundIcon
