import React from 'react'

import Modal from 'components/modal'
import { ImageItemProps, ProfileModalProps } from '../_types'
import { MissionType } from 'pages/AboutUs/features/our-mission/_types'

const ProfileModal = ({
  showModal,
  setShowModal,
  modalData,
  missions,
}: ProfileModalProps) => {
  const [displayContent, setDisplayContent] =
    React.useState<MissionType>(modalData)

  React.useEffect(() => {
    setDisplayContent(modalData)
  }, [modalData])

  const ImageItem = ({ imgSrc, mission }: ImageItemProps) => {
    return (
      <img
        src={imgSrc}
        alt={mission.name}
        key={imgSrc}
        className={`h-16 w-16 rounded cursor-pointer object-cover ${
          displayContent.image === imgSrc ? 'opacity-60' : ''
        }`}
        onClick={() => setDisplayContent(mission)}
      />
    )
  }

  const handleCloseModal = () => setShowModal(false)

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      handleCloseModal={handleCloseModal}>
      <>
        <div className="flex flex-col lg:flex-row font-primary border-4 border-solid border-black w-full lg:min-h-[60vh] rounded-[20px]">
          <img
            src={displayContent.image}
            className="shrink-0 h-2/5 max-h-[720px] rounded-t-[16px] lg:rounded-r-[0px] lg:h-full lg:max-h-full lg:w-1/3 lg:rounded-l-[16px] object-cover"
          />
          <div className="flex flex-col justify-between p-4 sm:p-6 lg:p-10">
            <div>
              <h1 className="font-secondary text-2xl font-bold mb-[9px]">
                {displayContent.name}
              </h1>
              <p className="text-base opacity-60 mb-2 lg:mb-5">
                {displayContent.position}
              </p>
              <p>{displayContent.description}</p>
            </div>
            <div className="flex items-center gap-4 mt-3">
              <a href={displayContent.linked_in} target="_blank">
                <img
                  className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
                  src="/images/linkedin_colored.svg"
                  alt="Share to LinkedIn"
                />
              </a>
              <a href={displayContent.facebook_link} target="_blank">
                <img
                  className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
                  src="/images/facebook_colored.svg"
                  alt="Share to Facebook"
                />
              </a>
              <a href={displayContent.twitter} target="_blank">
                <img
                  className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
                  src="/images/twitter_colored.svg"
                  alt="Share to Twitter"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="our-mission-grid grid grid-flow-col auto-cols-max overflow-x-auto justify-between gap-3 mt-8 pb-2 w-full sm:w-1/5 mx-auto">
          {missions &&
            missions.map((mission) => (
              <ImageItem
                key={mission.name}
                imgSrc={mission.image}
                mission={mission}
              />
            ))}
        </div>
      </>
    </Modal>
  )
}

export default ProfileModal
