import axiosInstance from './axios'

export const getPageList = async (): Promise<any[]> => {
  const res = await axiosInstance.get(`/dashboard/api/v1/pages/list`)
  return await res.data
}

export const getPageBySlug = async (slug: string): Promise<any> => {
  const res = await axiosInstance.get(`/dashboard/api/v1/pages/${slug}/detail`)
  return await res.data
}
