import React, { useState, useEffect } from 'react'

import {
  LeftGradient,
  RightGradient,
  BottomGradient,
} from 'pages/StepOne/features/why-choose-us'
import { DataPoint } from 'pages/AboutUs/features'
import ColoredHeading from 'ui/Text/ColorHeading'
import SectionHeading from 'ui/Text/SectionHeading'
import CustomTooltip from '../custom-tooltip'
import { GraphData } from '../custom-tooltip/_types'
import { getTimeline } from 'services/HomeServices'
import { initialTooltipData } from 'pages/StepOne/features/line-chart'
import { TimelineData } from 'pages/StepOne/features/line-chart/types'

const Timeline = () => {
  const [showTooltipYear, setShowTooltipYear] = React.useState('2008')
  const [yearsList, setYearsList] = useState<string[]>([])
  const [tooltipData, setTooltipData] =
    useState<TimelineData>(initialTooltipData)
  const [timelineData, setTimelineData] = useState<any>()
  const [graphData, setGraphData] = useState<GraphData[]>([])
  useEffect(() => {
    let years: string[] = []
    let graphPlots: GraphData[] = []
    const fetchData = async () => {
      const data = await getTimeline()
      console.log(data,'data')
      data.map((shareData) => {
        years.push(shareData.year.toString())
        graphPlots.push({
          time: shareData.year.toString(),
          stock_index: shareData.open,
        })
      })
      setGraphData(graphPlots)
      setYearsList(years.sort().reverse())
      let selectedYearData: TimelineData =
        data.find(
          (data: TimelineData) => data.year === parseInt(showTooltipYear),
        ) || initialTooltipData
      setTooltipData(selectedYearData)
      setTimelineData(data)
    }
    const result = fetchData()
  }, [])

  return (
    <div className="flex flex-col text-left py-10 px-6 md:py-20 md:px-15 overflow-hidden relative">
      <ColoredHeading label="OUR EXPERIENCES OVER THE LAST 25 YEARS." />
      <SectionHeading
        label="Learn, How ABC & NEPSE Grew Together."
        extraClass="mb-8"
      />
      <div className="grid grid-cols-12 gap-1 sm:gap-4 md:gap-8">
        <div className="flex flex-col items-center shrink-0 col-span-3 450:col-span-2 md:col-span-1">
          {yearsList.length > 0 &&
            yearsList.map((year, i) => (
              <DataPoint
                key={year}
                setShowTooltipYear={setShowTooltipYear}
                showTooltipYear={showTooltipYear}
                year={year}
                index={i}
                shareData={timelineData}
                setToolTipData={setTooltipData}
                setGraphData={setGraphData}
              />
            ))}
        </div>
        {showTooltipYear ? (
          <CustomTooltip graphData={graphData} tooltipData={tooltipData} />
        ) : (
          ''
        )}
      </div>
      <LeftGradient style={{ background: '#C9BBFF' }} />
      <RightGradient style={{ background: '#FAFFBB' }} />
      <BottomGradient style={{ background: '#FEB8B4' }} />
    </div>
  )
}

export default Timeline
