import { RecapData } from 'pages/AboutUs/features/custom-tooltip/_types'
import { TimelineData } from 'pages/StepOne/features/line-chart/types'
import formatCompactNumber from './numberFormatter'

export const initialRecapData = [
  {
    subHeading: 'High',
    subValue: 'N/A',
    firstChild: true,
    rise: true,
  },
  {
    subHeading: 'Low',
    subValue: 'N/A',
    rise: false,
  },
  {
    subHeading: 'Open',
    subValue: 'N/A',
  },
  {
    subHeading: 'Close',
    subValue: 'N/A',
  },
  // {
  //   subHeading: 'Capital Growth',
  //   subValue: 'N/A',
  // },
  {
    subHeading: 'Total Turnover',
    subValue: 'N/A',
  },
]

export const aboutRecapDataFormatter = (tooltipData: TimelineData) => {
  let recapData: RecapData[] = []

  for (let key in tooltipData) {
    switch (key) {
      case 'high':
        recapData.push({
          subHeading: 'High',
          subValue: formatCompactNumber(tooltipData[key])?.toString() || 'N/A',
          firstChild: true,
          rise: true,
        })
        break
      case 'low':
        recapData.push({
          subHeading: 'Low',
          subValue: formatCompactNumber(tooltipData[key])?.toString() || 'N/A',
          rise: false,
        })
        break
      case 'open':
        recapData.push({
          subHeading: 'Open',
          subValue: tooltipData[key].toString(),
        })
        break
      case 'close':
        recapData.push({
          subHeading: 'Close',
          subValue: tooltipData[key].toString(),
        })
        break
      // case 'abc_capital_growth':
      //   recapData.push({
      //     subHeading: 'Capital Growth',
      //     subValue: formatCompactNumber(tooltipData[key])?.toString() || 'N/A',
      //   })
      //   break
      case 'abc_total_turnover':
        recapData.push({
          subHeading: 'Total Turnover',
          subValue: formatCompactNumber(tooltipData[key])?.toString() || 'N/A',
        })
        break
    }
  }
  return recapData
}
