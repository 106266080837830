import { Link } from 'react-router-dom'
import ReactHTMLParser from 'react-html-parser'

import { BlogCardProps } from './_types'

const BlogCard = ({
  thumbnailSrc,
  title,
  description,
  category,
  date,
  author,
  id,
}: BlogCardProps) => {
  return (
    <Link
      to={`/blog-detail/${id}`}
      className="flex flex-row items-start gap-x-5 500:flex-col text-left w-full">
      <img
        src={thumbnailSrc}
        alt={title}
        className="shrink-0 w-25 400:w-[150px] h-25 rounded-xl 500:rounded-[36px] 500:w-full 500:h-[180px] object-cover"
      />
      <div className="flex flex-col gap-y-1.5 500:mt-5 w-full">
        <h3 className="font-secondary font-medium text-sm 400:text-base sm:text-lg !leading-[16px] sm:!leading-[24px]">
          {title?.length >= 40 ? `${title.slice(0, 36)}...` : title}
        </h3>
        <div className="flex flex-row justify-between items-center 350:items-start 350:flex-col gap-1">
          <div className="flex flex-col items-start gap-y-2 350:flex-row 350:justify-between 350:items-center w-full">
            <Link
              to={`/blog-category/${category}`}
              className="uppercase text-[#0C9FF5] font-bold font-inter text-xs 400:text-sm leading-[6px] w-1/2">
              {category && category.length > 0 ? category : 'Trading'}
            </Link>
            <span className="text-left 350:text-right text-[10px] 500:text-xs font-medium opacity-40 w-full 350:w-1/2">
              {date}
            </span>
          </div>
          <p className="hidden sm:block text-sm opacity-60 font-primary leading-[17px] h-[51px] overflow-hidden width-[40px]">
            {description && <>{ReactHTMLParser(description)}</>}
          </p>
          <span className="flex items-center grow-0 text-xs opacity-60 500:opacity-100 font-medium font-primary leading-[15px] 350:py-1 500:px-2 500:bg-black 500:bg-opacity-20 rounded-lg sm:max-w-[172px] sm:mt-3">
            <img
              src={author?.img}
              alt={author?.name}
              title={author?.name}
              className="350:hidden 500:block mr-2"
            />
            <span className="hidden 350:block">By {author?.name}</span>
          </span>
        </div>
      </div>
    </Link>
  )
}

export default BlogCard
