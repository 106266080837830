const CustomTooltip = ({ active, payload, label }: any) => {
  return (
    payload?.[0]?.payload?.time && (
      <div className="bg-white text-black rounded-lg border-0 overflow-hidden  p-2">
        <>
          <div>
            <div className="flex justify-between  gap-5">
              <span>Stock Index</span>
              <span>{payload?.[0]?.payload?.stock_index}</span>
            </div>
            <div className="flex justify-between  gap-5 mt-2">
              <span>Time</span>
              <span>
                {payload?.[0]?.payload?.time
                  ? new Date(payload?.[0]?.payload?.time).toLocaleTimeString()
                  : '-'}
              </span>
            </div>
          </div>
        </>
      </div>
    )
  )
}

export default CustomTooltip
