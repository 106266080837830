import React, {
  useEffect,
  useState,
} from 'react'

import { useWindowDimensions } from 'hooks'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getTimeline } from 'services/HomeServices'
import Button from 'ui/Button'

import CustomTooltip from '../custom-tooltip'
import {
  ActiveYear,
  LineChartContainer,
  VerticalLine,
  YearFilter,
  YearsContainer,
} from './styles'
import {
  TimelineData,
  TimelinePlots,
} from './types'

export let initialTooltipData = {
  id: 0,
  high: 0,
  low: 0,
  open: 0,
  close: 0,
  year: 0,
  abc_capital_growth: 0,
  abc_total_turnover: 0,
  event: [],
}

const LineChartTimeLine: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(2002)
  const [timelineData, setTimelineData] = useState<any>()
  const [graphLinePoints, setgraphLinePoints] = useState<TimelinePlots[]>([])
  const [years, setYears] = useState<number[]>([])
  const [displayYears, setDisplayYears] = useState<number[]>([])
  const [tooltipData, setTooltipData] =
    useState<TimelineData>(initialTooltipData)
  // tooltip for mobile view
  const [hideTooltip, setHideTooltip] = useState<boolean>(false)

  const { width } = useWindowDimensions()

  useEffect(() => {
    const fetchData = async () => {
      const data = await getTimeline()
      console.log(data, 'nepse data')
      setTimelineData(data)
      let timeLinePlots: TimelinePlots[] = []
      let yearsCopy: number[] = []
      console.log(data, 'nepse data')
      data.map((nepseData) => {
        timeLinePlots.push({ index: nepseData.open, Year: nepseData.year })
        yearsCopy.push(nepseData.year)
      })
      let sortedTimelinePlots = timeLinePlots.sort((a, b) => a.Year - b.Year)
      setgraphLinePoints(sortedTimelinePlots)
      setYears(yearsCopy.sort())
      let selectedYearData: TimelineData =
        data.find((data: TimelineData) => data.year == selectedYear) ||
        initialTooltipData
      setTooltipData(selectedYearData)
      return data
    }
    const result = fetchData()
  }, [])

  useEffect(() => {
    // setDisplayYears(years)

    if (width < 769) {
      const filteredYears = years?.filter((v) => v % 4 === 0)
      const startYear = years[0]
      const endYear = years[years.length - 1]
      setDisplayYears([startYear, ...filteredYears, endYear])
      // If it's a mobile view, the years will be displayed in the interval of 2
    } else {
      setDisplayYears(years)
    }
  }, [width, years])

  return (
    <>
      {graphLinePoints.length > 0 && (
        <>
          {/* <HorizontalScrollerDiv> */}
          <LineChartContainer>
            <ConditionalWrapper
              // condition={width > 880} // horizontal scrolling for width < 880
              condition={true}
              wrapper={(children: JSX.Element) => (
                <ResponsiveContainer aspect={3}>{children}</ResponsiveContainer>
              )}>
              <ComposedChart
                width={1000}
                height={600}
                data={graphLinePoints}
                margin={{ top: 25, right: 30, left: 20, bottom: 5 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="25%" stopColor="#000000" stopOpacity={0.4} />
                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <XAxis display={'none'} tick={{ fill: 'red' }} />
                <YAxis display={'none'} tick={{ fill: '#fff' }} />
                <Tooltip
                  cursor={false}
                  content={
                    width > 768 ? (
                      <CustomTooltip
                        customData={tooltipData}
                        wrapperStyle={{ pointerEvents: 'auto' }}
                        hideTooltip={hideTooltip}
                        timelineData={timelineData}
                      />
                    ) : (
                      <></>
                    )
                  }
                  wrapperStyle={{ outline: 'none' }}
                />
                <CartesianGrid
                  display={'none'}
                  horizontal
                  strokeDasharray="0 0"
                />

                <Line
                  type="monotone"
                  strokeWidth={6}
                  dataKey="index"
                  stroke="#000000"
                  dot={false}
                  legendType="none"
                />
                <Area
                  type="monotone"
                  dataKey="index"
                  stroke={'6'}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                  onClick={() => setHideTooltip(true)}
                  activeDot={{
                    r: 8,
                    onClick: (e, p: any) => {
                      console.log(p?.payload?.Year)
                      const year = p?.payload?.Year || null
                      setSelectedYear(year)
                      let selectedYearData = timelineData.find(
                        (data: TimelineData) => data.year == year,
                      )
                      setTooltipData(selectedYearData)
                    },
                  }}
                />
              </ComposedChart>
            </ConditionalWrapper>
          </LineChartContainer>
          <div className="relative">
            <hr className="my-8 h-0.5 bg-gray-300  border-0 dark:bg-gray-900 timeline-horizontal-line " />
            <YearsContainer items={displayYears.length}>
              {displayYears.map((year, i) => {
                return (
                  <div
                    key={i}
                    className="mr-2 cursor-pointer relative"
                    onClick={() => {
                      setSelectedYear(year)
                      let selectedYearData = timelineData.find(
                        (data: TimelineData) => data.year == year,
                      )
                      setTooltipData(selectedYearData)
                    }}>
                    <VerticalLine key={i} selectedYear={selectedYear == year}>
                      |
                    </VerticalLine>
                    <YearFilter selectedYear={selectedYear == year}>
                      {year}
                    </YearFilter>
                    {selectedYear == year && <ActiveYear />}
                  </div>
                )
              })}
            </YearsContainer>
          </div>
          {/* </HorizontalScrollerDiv> */}
          {width < 769 && (
            <div className="flex justify-center">
              <CustomTooltip
                customData={tooltipData}
                hideTooltip={hideTooltip}
                timelineData={timelineData}
              />
            </div>
          )}
          <div className="flex justify-end mr-8 mb-10 tablet:mt-10">
            <Button
              type="ghost"
              label={`Open Account`}
              icon={<img src="/images/arrow_right.png" />}
              extraClass="font-medium text-2xl h-16 h-12 text-lg flex justify-center items-center my-10"
              onClick={() => {
                window.open('https://kyc.abc.com.np', '_blank')
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean
  wrapper: (children: JSX.Element) => JSX.Element
  children: JSX.Element
}) => (condition ? wrapper(children) : children)

export default LineChartTimeLine
