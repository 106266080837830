import { useParams } from 'react-router'
import { useState, useEffect } from 'react'

import type { PaginationProps } from 'antd'
import { Pagination } from 'antd'

import { ChevronIcon } from 'ui/Icons'
import BlogCard from 'components/blog-card'
import PageHeader from 'components/page-header'
import { getBlogCategoryList } from 'services/BlogService'
import { BlogCardProps } from 'components/blog-card/_types'
import { initialBlogList } from 'pages/Blogs'

const controlButtonStyles =
  'flex items-center font-secondary font-medium text-[10px] p-2.5 500:py-0 border border-solid border-[#D9D9D9] rounded'

const BlogCategory = () => {
  const itemRender: PaginationProps['itemRender'] = (
    _,
    type,
    originalElement,
  ) => {
    if (type === 'prev') {
      return (
        <a className={controlButtonStyles}>
          <ChevronIcon className="mr-1" />
          <span className="hidden 500:block">Newer Posts</span>
        </a>
      )
    }
    if (type === 'next') {
      return (
        <a className={controlButtonStyles}>
          <span className="hidden 500:block">Older Posts</span>
          <ChevronIcon className="ml-1 rotate-180" />
        </a>
      )
    }
    return originalElement
  }
  const params = useParams()

  const [blogList, setBlogList] = useState<any>(initialBlogList)
  useEffect(() => {
    const fetchData = async () => {
      const data = await getBlogCategoryList(params.category)
      setBlogList({ ...data })
      return data
    }
    const result = fetchData()
  }, [])
  return (
    <div className="">
      <PageHeader
        heading="Business Updates"
        title={params.category || 'Trading'}
      />
      <div className="grid 500:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 place-items-center gap-x-5 gap-y-8 py-10 md:py-20 px-6 md:px-15 bg-white">
        {blogList && blogList.results && blogList.results.length > 0 ? (
          blogList.results.map((blog: BlogCardProps, index: number) => (
            <BlogCard
              key={index}
              thumbnailSrc={blog.thumbnailSrc}
              title={blog.title}
              description={blog.body}
              category={blog.category}
              date={blog.date}
              author={blog.author}
            />
          ))
        ) : (
          <></>
        )}
      </div>
      {blogList && blogList.results && blogList.results.length > 0 ? (
        <Pagination
          total={blogList.count}
          itemRender={itemRender}
          showSizeChanger={false}
          showLessItems
          pageSize={8}
          onChange={async (pageNumber) => {
            const data = await getBlogCategoryList(params.category, pageNumber)
            setBlogList(data)
          }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default BlogCategory
