import { FeatureCardProps } from './_types'

const FeatureCard = ({
  title = 'DEMAT Account',
  description = 'The name of the company was renamed to ABC Securities Pvt. Ltd. from Khandelwal Stock Broking Co. Pvt. Ltd.',
  iconSrc,
  extraClass = '',
}: FeatureCardProps) => {
  return (
    <div
      className={`flex flex-col shrink-0 items-center bg-white py-8 px-6 w-93.25 rounded-[10px] shadow-card 767:w-full ${extraClass}`}>
      <img src={iconSrc} alt="account type" height="80px" width="80px" />
      <span className="font-semibold font-secondary text-2xl my-3">
        {title}
      </span>
      <span
        className="text-base opacity-60"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

export default FeatureCard
