import ColoredHeading from 'ui/Text/ColorHeading'
import { LeftGradient, RightGradient } from 'components/header'
import SectionHeading from 'ui/Text/SectionHeading'
import { PageHeaderProps } from './_types'

const PageHeader = ({
  children,
  heading = '',
  title = 'Title here',
  extraClass = '',
}: PageHeaderProps) => {
  return (
    <div className="relative md:pb-10">
      <div
        className={`flex flex-col items-center md:w-2/3 px-5 sm:px-10 mx-auto ${extraClass}`}>
        <ColoredHeading label={heading} />
        <SectionHeading label={title} extraClass="mb-4" />
        {children}
      </div>
      <LeftGradient style={{ height: '100%', filter: 'blur(200px)', top: 0 }} />
      <RightGradient
        style={{ height: '100%', filter: 'blur(200px)', top: 0 }}
      />
    </div>
  )
}

const Description = ({ children }: { children: string }) => (
  <span className="font-primary font-medium 500:text-xl">{children}</span>
)

PageHeader.Description = Description
export default PageHeader
