import Wrapper from './Wrapper'

const InvestmentIcon = ({ className }: { className?: string }) => {
  return (
    <Wrapper>
      <g>
        <path d="M736 880c64-64 56.44-215.56-16-288-64-64-80-80-140.21-160-33.05-43.91-62.06-53.2-59-59 6.86-13.04 132.84 47.99 135.21 43 2.09-4.4-86.58-71.59-192-64-21.53 1.55-63.84 7.66-64 16-.24 12.54 94.67 47.08 160.12 5 38.44-24.72 53.3-63.32 59-78.67 15.98-42.98 11.61-84.89 4.87-86.33-5.82-1.24-12.36 27.95-41.98 44.8-3.24 1.84-3.03 1.42-6.02 3.2-25.69 15.28-26.42 37.79-35.54 38.33-10.92.64-22.82-30.87-28.46-54.33-9.26-38.56-2.98-61.1-10.88-63.67-8.66-2.82-28.44 20.27-37.12 47.67-14.17 44.75 5.66 87.15-2.21 90-4.52 1.63-9.75-12.79-29.79-26-14.02-9.24-19.5-7.47-29.21-13.34-30.74-18.56-25.88-66.41-34.79-66.66-8.2-.24-26.61 39.94-16 80 13.25 50.02 58.57 50.07 70.45 85 19.69 57.85-52.63 134.05-157.34 240.78-57.12 58.22-82.49 168.79-39.33 236.01 38.47 59.9 123.91 46.79 270.22 46.21 95.15-.37 304 32 464 52.12" />
        <path d="M571.2 584.02c-23.11-40.82-65.56-50.64-92.8-28.39-23.89 19.51-35.39 63.12-23.2 85.18 20.48 37.09 95.66-8.79 116 28.39 12.46 22.79-.81 67.35-23.2 85.18-30 23.89-85.14 6.77-116-56.79M524.8 816V496M496 816V496" />
      </g>
    </Wrapper>
  )
}

export default InvestmentIcon
