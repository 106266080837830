const ChevronIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="12"
      fill="none"
      className={className}>
      <path
        fill="currentColor"
        d="M6.84 2.066V1.031c0-.09-.103-.14-.173-.084L.63 5.662a.427.427 0 0 0 0 .674l6.037 4.716a.107.107 0 0 0 .173-.085V9.932a.217.217 0 0 0-.082-.169L1.937 6l4.821-3.765a.217.217 0 0 0 .082-.169Z"
      />
    </svg>
  )
}

export default ChevronIcon
