import React from 'react'

import { postEmail } from 'services/HomeServices'
import SubHeading from 'ui/Text/SubHeading'
import Button from 'ui/Button'
import Field from 'ui/InputField'

const LetsGrowTogether = () => {
  const [email, setEmail] = React.useState('')
  const [content, setContent] = React.useState('joinUs')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errorData, setErrorData] = React.useState<string>('')

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setEmail(e.target.value)
  }
  const onConfirm = async () => {
    try {
      setLoading(true)
      const resp: any = await postEmail({ email })
      if (resp.status === 201) {
        setContent('thankYou')
      }
    } catch (e: any) {
      setErrorData(e?.response?.data?.email[0])
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="relative bg-white flex flex-col items-center py-10 md:py-20">
      <h2 className="text-[32px] leading-[32px] md:leading-[48px] my-4 767:text-[24px]  font-medium font-secondary">
      Subscribe to our platform to stay ahead of market trends
      </h2>
      <SubHeading
        label="Subscribe to our Newsletter for Market Updates, Headlines, and ABC Transformation."
        extraClass="my-6"
      />
      {content === 'joinUs' ? (
        <Button
          type="ghost"
          label="Join Us"
          extraClass="font-medium text-xl h-16 w-33.75"
          onClick={() => setContent('emailBox')}
        />
      ) : content === 'emailBox' ? (
        <Field
          label=""
          id="email"
          type="email"
          error={errorData}
          extraClass="z-10 500:w-2/3 sm:w-1/2">
          <span className="flex w-full">
            <Field.Input
              name="email"
              value={email}
              variation="cv"
              placeholder="Email Address"
              onChange={handleChange}
              extraClass="h-16 rounded-l-lg rounded-r-none"
            />
            <Button
              type="primary"
              label="Confirm"
              extraClass="shrink-0 uppercase font-medium text-lg h-16 rounded-none rounded-r-lg w-[129px]"
              onClick={onConfirm}
              loading={loading}
            />
          </span>
        </Field>
      ) : (
        <Button
          type="ghost"
          label="Thank you, we will keep you updated!"
          extraClass="z-10 bg-white font-medium text-base 500:text-xl h-16 w-auto lg:w-1/2"
          onClick={() => setContent('joinUs')}
        />
      )}
      <img
        src="/images/left_vector.svg"
        alt="design thread"
        className="hidden xl:block absolute left-0"
      />
      <img
        src="/images/right_vector.svg"
        alt="design thread"
        className="hidden xl:block absolute right-0 bottom-2"
      />
    </div>
  )
}

export default LetsGrowTogether
