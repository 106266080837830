import styled from 'styled-components'

import { AuthorInfo as AuthorInfoProps } from './_types'

export default function AuthorInfo({ authorImg, authorName }: AuthorInfoProps) {
  return (
    <AuthorContainer>
      <img src={authorImg} width={'32px'} height={'32px'} />
      <AuthorDescription>
        By <span>{authorName}</span>
      </AuthorDescription>
    </AuthorContainer>
  )
}

export const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: max-content;
`

export const AuthorDescription = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
`
