import React from 'react'
import { Collapse } from 'antd'

import Accordion from 'components/accordion'
import { FaqsTabTypes } from '../custom-tabs/_types'

let { Panel } = Collapse

const FaqTab = ({ category, data }: FaqsTabTypes) => {
  return (
    <Accordion>
      {data
        ?.filter((faqs: any) => faqs.name === category)
        ?.map(({ name, faqs }: any) => (
          <React.Fragment key={name}>
            {faqs?.map(({ question, answer }: any) => (
              <Panel
                header={question}
                key={question}
                className="font-medium text-base">
                {answer}
              </Panel>
            ))}
          </React.Fragment>
        ))}
    </Accordion>
  )
}

export default FaqTab
