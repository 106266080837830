import React from 'react'
import { Carousel } from 'antd'
import { CarouselRef } from 'antd/es/carousel'

import { getOurFeatures } from 'services/HomeServices'
// import ColoredHeading from 'ui/Text/ColorHeading'
// import SectionHeading from 'ui/Text/SectionHeading'
import DematCard from '../demat-card'
import { CardData } from '../_types'

const OurFeatures = () => {
  const [cardsData, setCardsData] = React.useState<CardData[]>([])
  const [currentData, setCurrentData] = React.useState<CardData>({
    id: 2,
    title: 'DEMAT Account',
    subtitle: 'Create your DEMAT Account',
    image: '/images/home/slide1.png',
  })
  const carouselRef: React.Ref<CarouselRef> = React.useRef(null)
  React.useEffect(() => {
    const fetchData = async () => {
      const resp: any = await getOurFeatures()
      setCardsData(resp.results)
    }
    fetchData()
  }, [getOurFeatures])

  // Extract all the images for showing through the carousel
  const carouselImages = cardsData?.reduce((temp, init) => {
    temp.push(init.image)
    return temp
  }, [] as string[])

  const handleGOTO = (index: number) => {
    carouselRef?.current?.goTo?.(index)
    setCurrentData(cardsData[index])
  }

  return (
    <div className="bg-white py-10 px-5 sm:py-20 md:px-15 relative">
      <div className="flex flex-col justify-center items-center lg:flex-row lg:items-start lg:justify-center">
        {/* <section className="flex flex-col">
          <ColoredHeading label="About ABC Securities" />
          <SectionHeading label="Our Features" />
        </section> */}
        <div className="text-base opacity-100 w-auto lg:w-145 mt-6 lg:mt-0 bg-white z-1">
          <p className="text-base opacity-100 w-auto lg:w-145 mt-6 lg:mt-0 bg-white z-1">
            <span className="opacity-60">
              Starting from the late 1990s, we are committed to introducing
              efficient and simple ways to access the Capital Market of Nepal.
            </span>
          </p>
          <p className="text-base opacity-100 w-auto lg:w-145 mt-4 lg:mt-5 bg-white z-1">
            <span className="opacity-60">
              Open your Trading account, DEMAT or Mero Share from your browser
              digitally.
            </span>
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-3 sm:gap-x-0 justify-items-center items-center mt-15">
        <div className="grid grid-cols-1 gap-y-10 sm:gap-y-20">
          {cardsData?.slice(0, 3).map((card) => (
            <DematCard
              title={card.title}
              companyName={card.subtitle}
              key={card.id}
              onMouseOver={handleGOTO}
              currentDataIndex={cardsData.indexOf(currentData)}
              cardKey={cardsData.indexOf(card)}
            />
          ))}
        </div>
        {/* <div className="relative grid-cols-1 gap-y-20 justify-self-end hidden lg:block z-2 w-[300px] h-[700px] xl:w-[375px] xl:h-[753px]"> */}
        <div className="relative grid-cols-1 gap-y-20 justify-self-center hidden lg:block z-2 w-[300px] h-[700px] xl:w-[375px] xl:h-[753px]">
          <img
            src="/images/home/iphone_layout_with_icons.png"
            alt="mobile app for ABC"
            className="absolute -top-6 z-10 rounded-3xl"
          />
          <Carousel
            autoplay
            dots={false}
            beforeChange={(prev, next) => {
              setCurrentData(cardsData[next])
            }}
            ref={carouselRef}
            className="overflow-hidden rounded-[15%]">
            {carouselImages.map((image, index) => (
              <img
                src={image}
                key={`${index}${image}`}
                className="w-[260px] h-[560px] xl:w-[328px] xl:h-[710px] object-contain"
              />
            ))}
          </Carousel>
        </div>
        <img
          src="/images/mobile_line_vector.svg"
          alt="thread for design"
          className="hidden lg:block absolute left-10 top-0"
        />
        <div className="grid grid-cols-1 gap-y-10 sm:gap-y-20">
          {cardsData?.slice(3, 6).map((card) => (
            <DematCard
              title={card.title}
              companyName={card.subtitle}
              key={card.id}
              onMouseOver={handleGOTO}
              currentDataIndex={cardsData.indexOf(currentData)}
              cardKey={cardsData.indexOf(card)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default OurFeatures
