import { Collapse } from 'antd'
import { menuItems } from 'constants/pages/footer'
import { Link } from 'react-router-dom'

import { AccordionMenuProps } from './_types'

let { Panel } = Collapse

const AccordionMenu = ({ extraClass }: AccordionMenuProps) => {
  return (
    <Collapse
      accordion
      bordered={false}
      className={`font-primary ${extraClass}`}
      expandIconPosition="end">
      {menuItems.map(({ title, subItems }) => (
        <Panel header={title} key={title} className="font-medium text-lg">
          <ul>
            {subItems.map(({ name, href }) => (
              <li key={name} className="py-2">
                <Link
                  className="font-normal text-base hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
                  to={href}
                  target="_blank">
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </Panel>
      ))}
    </Collapse>
  )
}

const FooterMenu = () => {
  return (
    <div className="flex flex-col items-center 1.5xl:flex-row 1.5xl:items-start 1.5xl:justify-between mb-8 1500:justify-center">
      <section className="flex flex-col items-start mb-5 1.5xl:mb-0 1500:mr-[40px]">
        <Link to="/">
          <img src="/images/abc_logo.svg" alt="ABC securities logo" />
        </Link>
        <span className="font-primary text-base mt-4">
          © Copyright {new Date().getFullYear()}
        </span>
      </section>
      <section className="hidden md:grid grid-cols-2 justify-end gap-x-32 gap-y-9 lg:grid-cols-4 lg:gap-9">
        {menuItems.map((menuItem) => (
          <ul key={menuItem.title}>
            <li>
              <p className="text-lg font-medium mb-6">{menuItem.title}</p>
              <ul>
                {menuItem.subItems.map(({ name, href }) => (
                  <li className="text-base leading-8" key={name}>
                    <Link
                      className="hover:shadow-linkHover hover:bg-hoverLink hover:rounded"
                      to={href}
                      target={
                        menuItem.title.toLowerCase() !== 'company' &&
                        menuItem.title.toLowerCase() !== 'support'
                          ? '_blank'
                          : ''
                      }>
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        ))}
      </section>
      <AccordionMenu extraClass="block md:hidden" />
    </div>
  )
}

export default FooterMenu
