import HtmlParser from 'react-html-parser'

import Button from 'ui/Button'
import ColoredHeading from 'ui/Text/ColorHeading'
import { ServiceBlockProps } from '../_types'

const ServiceBlock = ({
  title = '',
  subtitle = '',
  description = '',
  icon = '',
}: ServiceBlockProps) => {
  return (
    <div className="py-10 px-6 500:px-15 bg-white flex flex-col lg:flex-row lg:odd:flex-row-reverse items-center justify-between">
      <div className="flex flex-col items-start text-left lg:w-1/2">
        <ColoredHeading label={title} extraClass="mb-4" />
        <span className="font-secondary font-bold text-xl 500:text-[32px] leading-[38.4px] mb-4">
          {subtitle}
        </span>
        <span className="font-primary test-base opacity-60">
          {HtmlParser(description)}
        </span>
        <Button
          type="ghost"
          label={`Learn More`}
          icon={<img src="/images/arrow_right.png" />}
          extraClass="font-medium text-lg 500:text-2xl h-16 h-16 flex justify-center items-center mt-4"
        />
      </div>
      <img src={icon} alt={title} className="mt-5 w-0 lg:w-1/3 lg:mt-0" />
    </div>
  )
}

export default ServiceBlock
