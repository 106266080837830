import {
  RecapContainer,
  SubHeading,
  SubHeadingValue,
} from 'pages/StepOne/features/custom-tooltip/styles'
import { RecapProps } from './_types'

const Recap = ({
  subHeading,
  subValue,
  imgSrc,
  rise = undefined,
  fullBorder,
  firstChild,
  lastChild,
  about = false,
}: RecapProps) => {
  return (
    <RecapContainer
      fullBorder={fullBorder}
      firstChild={firstChild}
      lastChild={lastChild}
      about={about}>
      <SubHeading about={about}> {subHeading} </SubHeading>
      <SubHeadingValue about={about}> {subValue} </SubHeadingValue>
      {/* {rise !== undefined && (
        <img
          src={
            rise ? '/images/upwards_vector.svg' : '/images/downwards_vector.svg'
          }
          height="8px"
          width="13.33px"
          alt="fall"
          className="inline ml-0.5"
        />
      )} */}
    </RecapContainer>
  )
}

export default Recap
